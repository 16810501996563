import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ModalContainer = styled.div`
  display: ${({ visibled }) => (visibled ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  height: 200px;
  overflow-x: scroll;
  z-index: 2;
  border: 1px solid ${({ theme }) => theme.colors.grayMedium};
  border-radius: 5px;
  box-shadow: 0 0 18px 1px rgba(0, 0, 0, 0.55);
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ModalOption = styled.div`
  width: 100%;
  height: 40px;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayMedium};
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

export const OptionLoading = styled.div`
  width: 90%;
`;

export const Label = styled.span`
  font-size: 11pt;
  color: ${({ theme }) => theme.colors.grayDark};
`;
