import styled from 'styled-components';

export const ContainerLoading = styled.div`
  width: 100%;
  max-width: 445px;
  border-radius: ${({ theme }) => theme.border.radius};
`;

export const ContainerError = styled.div`
  width: 100%;
  max-width: 445px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.skeletonBase};
  border-radius: ${({ theme }) => theme.border.radius};
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 10px;
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 68px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: ${({ theme, color }) => color || theme.colors.light};

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: self-start;
    gap: 16px;
    white-space: nowrap;
  }
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ theme, titleColor }) => titleColor || theme.colors.primary}!important;
  font-weight: bold;

  svg {
    color: ${({ theme, titleColor }) => titleColor || theme.colors.primary};
    font-size: 22px;
    margin-right: 10px;
  }
`;

export const Register = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;

  :hover {
    filter: brightness(0.9);
  }
`;
