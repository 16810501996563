import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { createGlobalStyle } from 'styled-components';
import { Typography } from '../constants';
import Theme from './theme';

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    margin: 0;
  }

  body, button, input, select, textarea, li, ul, p, b, label, span, h1, h2, h3, h4, h5, h6 {
    font-family: ${Typography.fontFamilyBaseDefault};
  }

  h1, h2, h3, h4, h5, h6,
  a, code, p, strong, b, span {
    color: ${Theme.colors.black};
    font-weight: ${Typography.fontWeightNormal};
    &.-is-bold {
      font-weight: ${Typography.fontWeightBold};
    }

    &.-is-light {
      font-weight: ${Typography.fontWeightLight};
    }

    &.-is-primary {
      color: ${Theme.colors.primary};
    }

    &.-is-white {
      color: ${Theme.colors.white};
    }

    &.-is-gray {
      color: ${Theme.colors.gray};
    }

    &.-is-grayLight {
       color: ${Theme.colors.grayLight};
     }
  }

  h1, .h1 {
    font-size: ${Typography.h1};
    font-weight: 500;
  }

  h2, .h2 {
    font-size: ${Typography.h2};
  }

  h3, .h3 {
    font-size: ${Typography.h3};
  }

  h4, .h4 {
    font-size: ${Typography.h4};
  }

  h5, .h5 {
    font-size: ${Typography.h5};
  }

  h6, .h6 {
    font-size: ${Typography.h6};
  }

  a, p {
    font-size: ${Typography.p};
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  span.--extract-negative {
    color: ${Theme.colors.error} !important;
  }

  span.--extract-positive {
    color: ${Theme.colors.success}!important;
  }

  div.modal-content {
    &.--overflow-visible {
      overflow: visible;
    }
  }

  @keyframes fadeInLineAnimation {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 500px
    }
  }

  @keyframes fadeOutLineAnimation {
    0% {
      max-height: 500px;
    }
    100% {
      max-height: 0;
    }
  }
`;
export default GlobalStyles;
