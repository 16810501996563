import styled, { css } from 'styled-components';

const wrapperModifiers = {
  primary: (theme) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    transition: ${theme.transition.default};

    &:hover {
      background-color: ${theme.colors.primaryDark};
    }
  `,
  primaryDark: (theme) => css`
    background-color: ${theme.colors.primaryDark};
    color: ${theme.colors.white};
    transition: ${theme.transition.default};

    &:hover {
      filter: brightness(0.9);
    }
  `,
  secondary: (theme) => css`
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
    border: 1px solid ${theme.colors.secondary};
    transition: ${theme.transition.default};

    &:hover {
      background-color: ${theme.colors.secondaryDark};
      border: 1px solid ${theme.colors.secondaryDark};
      color: ${theme.colors.white};
    }
  `,
  tertiary: (theme, isOutline) => css`
    background-color: ${isOutline ? theme.colors.white : theme.colors.tertiary};
    color: ${isOutline ? theme.colors.tertiary : theme.colors.white};
    border: solid ${theme.colors.tertiary} ${isOutline ? '2px' : '1px'};
    transition: ${theme.transition.default};

    &:hover {
      background-color: ${theme.colors.tertiaryDark};
      border: 1px solid ${theme.colors.tertiaryDark};
      color: ${theme.colors.white};
    }
  `,
  warning: (theme) => css`
    background-color: ${theme.colors.warning};
    color: ${theme.colors.white};
    border: 1px solid ${theme.colors.warning};
    transition: ${theme.transition.default};

    &:hover {
      background-color: ${theme.colors.warningDark};
      border: 1px solid ${theme.colors.warningDark};
      color: ${theme.colors.white};
    }
  `,
  error: (theme) => css`
    background-color: ${theme.colors.error};
    color: ${theme.colors.white};
    border: 1px solid ${theme.colors.error};
    transition: ${theme.transition.default};

    &:hover {
      background-color: ${theme.colors.errorDark};
      border: 1px solid ${theme.colors.errorDark};
      color: ${theme.colors.white};
    }
  `,
  white: (theme) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    transition: ${theme.transition.default};

    &:hover {
      background-color: ${theme.colors.offWhite};
      border: 1px solid ${theme.colors.primaryDark};
      color: ${theme.colors.primaryDark};
    }
  `,
  gray: (theme) => css`
    background-color: ${theme.colors.gray};
    color: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray};
    transition: ${theme.transition.default};

    &:hover {
      background-color: ${theme.colors.grayMedium};
      border: 1px solid ${theme.colors.grayMedium};
      color: ${theme.colors.white};
    }
  `,
  link: (theme) => css`
    background-color: ${theme.colors.transparent};
    color: ${theme.colors.textPrimary};

    &:hover {
      text-decoration: underline;
    }

    &:focus,
    &:active {
      color: ${theme.colors.primaryDark};
    }
  `,
  small: (theme) => css`
    font-size: ${theme.font.btn.small};
    height: 35px;
    min-width: 80px;
    padding: 7px;
    svg.--loading-btn {
      font-size: calc(${theme.font.btn.small} + 0.575rem);
    }
  `,
  medium: (theme) => css`
    font-size: ${theme.font.btn.medium};
    height: 48px;
    padding: 17px 30px;
    min-width: 180px;
    svg {
      font-size: calc(${theme.font.btn.medium} + 0.425rem);
    }
    svg.--loading-btn {
      font-size: calc(${theme.font.btn.medium} + 0.575rem);
    }
  `,
  large: (theme) => css`
    font-size: ${theme.font.btn.large};
    height: 48px;
    min-width: 348px;
    gap: 20px;
    svg.--loading-btn {
      font-size: calc(${theme.font.btn.large} + 0.575rem);
    }
  `,
  modal: (theme) => css`
    font-size: ${theme.font.btn.medium};
    height: 48px;
    min-width: 175px;
    padding: 17px 38px;
    svg.--loading-btn {
      font-size: calc(${theme.font.btn.medium} + 0.575rem);
    }
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  disabled: () => css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `,
};

export const Button = styled.button`
  ${({ theme, bold, size, full, variant, outline, disabled }) => css`
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    gap: 12px;
    border-radius: ${theme.border.radius};
    font-family: ${theme.font.family.normal};
    font-weight: ${bold ? '700' : '500'};
    justify-content: center;
    text-decoration: none;

    position: relative;
    overflow: hidden;
    transition: background 400ms;
    color: #fff;
    padding: 18px 30px;
    outline: 0;
    border: 0;

    ${!!size && wrapperModifiers[size](theme, outline)};
    ${!!variant && wrapperModifiers[variant](theme, outline)};
    ${!!full && wrapperModifiers.fullWidth()};
    ${disabled && wrapperModifiers.disabled(theme)};

    @keyframes ripple {
      to {
        transform: scale(4);
        opacity: 0;
      }
    }

    svg.--loading-btn {
      animation: rotation 0.8s linear infinite;
    }

    svg:focus {
      outline: none;
    }

    span.ripple {
      position: absolute; /* The absolute position we mentioned earlier */
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 600ms linear;
      background-color: rgba(255, 255, 255, 0.3);
    }
  `}
`;
