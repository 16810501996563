import styled from 'styled-components';

export const ContainerError = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${({ theme }) => theme.border.color};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.skeletonBase};
  border-radius: ${({ theme }) => theme.border.radius};
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 10px;
`;
