import styled from 'styled-components';
import { ASSETS } from '~/constants';

const INPUT_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
};

export const Container = styled.div`
  width: 100%;
`;

export const RadioInput = styled.input`
  position: relative;
  border: none;
  margin-right: 0.5rem;
  cursor: pointer;
  width: 17px;
  height: 17px;
  color: ${({ error, theme }) => (error ? theme.colors.error : theme.colors.black)} !important;
  accent-color: ${({ type, theme }) =>
    type === INPUT_TYPE.RADIO ? theme.colors.white : theme.colors.success} !important;

  &::before {
    width: 17px;
    height: 17px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.background} !important;
    border: 1.5px solid ${({ error, theme }) => (error ? theme.colors.error : theme.colors.grey)} !important;
    border-radius: ${({ type }) => (type === INPUT_TYPE.RADIO ? '50%' : 0)};
  }

  &:checked {
    content: url(${({ type, withCustomContent }) =>
      type === INPUT_TYPE.RADIO
        ? withCustomContent || ASSETS.ICONS.RADIO_CHECKED
        : ASSETS.ICONS.CHECKBOX_CHECKED});
    width: 17px;
    height: 17px;
    border: 1.5px solid ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 10px 0 0;
  padding-left: 0;
  label {
    margin-bottom: 0 !important;
  }
`;

export const RadioRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const CustomLabel = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ error, theme }) => (error ? theme.colors.error : theme.colors.gray)} !important;
`;
