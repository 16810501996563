import styled from 'styled-components';
import themeDefault from '~/styles/theme';

export const Container = styled.div`
  position: relative;
`;

export const IconInfo = styled.img``;
export const Polygon = styled.img`
  position: absolute;
  top: -16px;
  left: 4px;
`;
export const Ballon = styled.div`
  position: absolute;
  background: ${themeDefault.colors.white};
  border-radius: 4px;
  padding: 15px;
  width: 312px;
  height: 103px;
  color: #868e96;
  filter: drop-shadow(0px 4.65493px 11.6373px rgba(0, 0, 0, 0.25));
`;
