import { takeLatest, call, put, all } from 'redux-saga/effects';
import qs from 'qs';

import { GetAccounts } from '~/services';
import { CATCH_PAYLOAD } from '~/constants';
import { toast } from 'react-toastify';
import { dataAccounts } from '~/mocks/dataAccounts';
import { REQUEST_ACCOUNTS, FETCH_LIST_ACCOUNTS } from './patterns';
import { setListAccounts } from './actions';

const SET_MOCK = true;

export function* sagaRequestAccounts({ payload }) {
  try {
    const prepareParams = qs.stringify(payload.params);
    const { data } = yield call(() => GetAccounts(prepareParams));

    yield put(
      setListAccounts({
        data,
      }),
    );
  } catch (err) {
    if (SET_MOCK) {
      yield put(
        setListAccounts({
          data: dataAccounts,
        }),
      );
    } else {
      yield put({ type: FETCH_LIST_ACCOUNTS, payload: CATCH_PAYLOAD });
      toast.error(err?.data?.error || err?.data?.message || err?.message);
    }
  }
}

export default all([takeLatest(REQUEST_ACCOUNTS, sagaRequestAccounts)]);
