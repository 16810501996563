import PropTypes from 'prop-types';
import React from 'react';
import { XCircle } from 'phosphor-react';
import * as S from './style';

function Chip({ variant, children, remove, ...props }) {
  return (
    <S.Chip variant={variant} {...props} className="chip-container">
      {children}
      {remove && <XCircle size={20} onClick={remove} />}
    </S.Chip>
  );
}

Chip.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'gray']),
};

Chip.defaultProps = {
  variant: 'primary',
};

export default Chip;
