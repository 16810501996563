import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const useFormHook = (schema, defaultValues) => {
  const formFunctions = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  return {
    formFunctions,
  };
};
