import * as S from '~/components/atoms/CheckedGroup/style';
import { LabelInput } from '~/components/styled';
import React from 'react';
import { INPUT_TYPE } from '~/constants';

function Custom({
  id,
  name,
  label,
  value,
  withCustomContent,
  onChange,
  checked,
  register,
  error,
  type,
  disabled,
  ...rest
}) {
  return (
    <S.RadioContainer>
      <S.RadioInput
        disabled={disabled}
        id={`${id}-${value}`}
        type={type}
        name={name}
        value={value}
        error={error}
        withCustomContent={withCustomContent}
        checked={checked}
        onChange={(event) => {
          if (type === INPUT_TYPE.CHECKBOX) onChange(value, event.currentTarget.checked);

          if (type === INPUT_TYPE.RADIO) onChange(value);
        }}
        {...rest}
      />
      <LabelInput
        error={error}
        htmlFor={`${id}-${value}`}
        data-testid={`web-radio-input-label-${value}`}
      >
        {label}
      </LabelInput>
    </S.RadioContainer>
  );
}

export default Custom;
