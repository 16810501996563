import produce from 'immer';
import { FETCH_ACCOUNT_GROUPING, REQUEST_ACCOUNTS, SET_GROUPING } from './patterns';

const INITIAL_STATE = {
  associatedAccounts: {
    requesting: false,
    error: false,
    list: {
      totalCount: 0,
      accounts: [],
    },
  },
};

export default function groupings(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_GROUPING: {
        draft.associatedAccounts = {
          requesting: false,
          error: false,
          list: action.payload.data,
        };
        break;
      }
      case REQUEST_ACCOUNTS: {
        draft.associatedAccounts.requesting = true;
        break;
      }
      case FETCH_ACCOUNT_GROUPING: {
        draft.associatedAccounts.requesting = action.payload.requesting;
        draft.associatedAccounts.error = action.payload.error;
        break;
      }
      default:
    }
  });
}
