import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { BannerHome, ContainerPage, Header } from '~/components/molecules';
import { JWT_TOKEN_STORAGE, MENU_MAIN, PROFILE_TYPES } from '~/constants';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Footer from '~/components/molecules/Footer';
import { getToken } from '~/services';
import * as S from './style';

function Wrapper({ children }) {
  const { t } = useTranslation('default');

  const navigate = useNavigate();
  const location = useLocation();

  const isMainArea = location.pathname.includes(PROFILE_TYPES.MAIN);
  const isHome = location.pathname.includes(PROFILE_TYPES.HOME);

  const { invalidToken } = useSelector((state) => state.auth);
  const isUnamed = false;

  useEffect(() => {
    const tokenStorage = localStorage.getItem(JWT_TOKEN_STORAGE);
    if (!tokenStorage) getToken();
  }, [navigate, location]);

  return (
    <S.Container>
      {isMainArea && <Header menuNavigation={MENU_MAIN} label={null} />}
      {isHome && <BannerHome />}
      <S.Body>
        <ContainerPage isHome={isHome}>{children}</ContainerPage>
      </S.Body>
      {isUnamed ? (
        <S.ContainerUnamed>
          <p>{t('partner_not_found')}</p>
        </S.ContainerUnamed>
      ) : null}
      {invalidToken ? (
        <S.ContainerUnamed>
          <p>{t('invalid_token')}</p>
        </S.ContainerUnamed>
      ) : null}
      {isHome && <Footer />}
    </S.Container>
  );
}

export default Wrapper;
