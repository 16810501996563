import React from 'react';
import { Button } from '~/components/atoms';
import uniqId from 'uniqid';
import { useNavigate } from 'react-router-dom';
import Banner from '../Banner';
import Row from '../Row';
import Col from '../Col';
import ContainerPage from '../ContainerPage';
import * as S from './style';

function Header({ menuNavigation, label }) {
  const navigate = useNavigate();

  return (
    <S.Container>
      <Banner label={label} />
      <S.MenuNavigation>
        <ContainerPage>
          <Row>
            {menuNavigation.map((elm) => (
              <Col className="is-4" key={uniqId()}>
                <Button
                  title={elm.title}
                  fullWidth
                  variant="white"
                  iconLeft={elm.icon}
                  onClick={() => navigate(elm.route)}
                />
              </Col>
            ))}
          </Row>
        </ContainerPage>
      </S.MenuNavigation>
    </S.Container>
  );
}

export default Header;
