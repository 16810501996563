import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import Routes from './routes';
import theme from './styles/theme';

import GlobalStyle from './styles/global';
import store from './store';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <ToastContainer position="bottom-right" />
          <Routes />
          <GlobalStyle />
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
