import styled from 'styled-components';
import { ASSETS } from '~/constants';

export const Container = styled.section`
  background: url(${ASSETS.IMAGES.BANNER_HOME}) no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 321px;
  display: flex;
  align-items: center;

  @media (min-width: 768px) and (max-width: 1024px) {
    background-position: 78% 0;
  }

  @media (max-width: 768px) {
    background-position: 80% 0px;
    align-items: end;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 321px;
  overflow: hidden;
`;

export const Background = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  width: 60%;
  height: 100%;
  background: #10384f;
  opacity: 0.8;
  transform: skewX(-10deg);

  @media (max-width: 768px) {
    top: 200px;
    left: 0;
    right: 0;
    height: calc(120% - 150px);
    opacity: 1;
    width: 100%;
    transform: translateX(0) skewY(-3deg);
  }
`;

export const Line = styled.div`
  background-color: #de0043;
  left: 59.5%;
  width: 1px;
  height: 110%;
  top: -20px;
  bottom: auto;
  position: absolute;
  z-index: 1;
  transform: translateX(0) rotate(15deg);

  @media (max-width: 768px) {
    transform: translateX(0) rotate(-7deg);
    top: 200px;
    width: 100%;
    height: 1px;
    left: 0;
    position: absolute;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1200px) {
    max-width: 1140px !important;
  }

  @media (max-width: 992px) {
    max-width: 960px;
  }

  @media (max-width: 768px) {
    max-width: 720px;
  }
`;

export const Title = styled.h1`
  position: relative;
  z-index: 2;
  font-weight: 100;
  font-size: 60px;
  color: white;
  margin: 0;
  width: 60%;

  @media (max-width: 768px) {
    font-size: 30px;
    width: 100%;
    margin-bottom: 10px;
  }
`;
