import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.actionPosition};
  ${({ hasCancel }) => (hasCancel ? 'gap: 20px' : '')};
  width: 100%;
  margin-top: 20px;
  button {
    margin-bottom: 10px;
  }
`;
