import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { ErrorContainerInput, ErrorInput } from '~/components/styled';
import { INPUT_TYPE } from '~/constants';
import * as S from './style';
import Custom from './Custom';

function RadioGroup({ id, name, label, options, value, error, onChange, register }) {
  const [checkedValue, setCheckedValue] = useState(value);

  const { onChange: onChangeRegister, onBlur } = register ? register(name) : { onChange };

  return (
    <S.Container data-testid="web-radio-input-container">
      {label ? (
        <S.CustomLabel data-testid="web-radio-input-label" error={error?.message}>
          {label}
        </S.CustomLabel>
      ) : null}

      <S.RadioRow>
        {options.map((op) => (
          <Custom
            id={id || name}
            key={shortid()}
            type={INPUT_TYPE.RADIO}
            name={name}
            label={op.label}
            value={op.value}
            withCustomContent={op.withCustomContent}
            error={error?.message}
            register={register}
            onChange={(chose) => {
              setCheckedValue(chose);
              onChange(chose);
              if (register)
                onChangeRegister({
                  target: { value: chose, name },
                });
            }}
            checked={op.value === checkedValue}
            onBlur={onBlur}
          />
        ))}
      </S.RadioRow>
      <ErrorContainerInput>
        {error?.message ? (
          <ErrorInput data-testid="web-radio-input-error">{error.message}</ErrorInput>
        ) : null}
      </ErrorContainerInput>
    </S.Container>
  );
}

RadioGroup.propTypes = {
  /** ID for the Input */
  id: PropTypes.string,
  /** label for the Input */
  label: PropTypes.string,
  /** Name for the Input */
  name: PropTypes.string,
  /** ID for the Input */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  /** Corresponding value for the Input in the component */
  value: PropTypes.any,
  /** Provide function to handle text Input changes */
  onChange: PropTypes.func,
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Error message */
  error: PropTypes.object,
};

RadioGroup.defaultProps = {
  id: null,
  name: null,
  label: null,
  value: '',
  onChange: () => {},
  register: undefined,
  error: {},
};

export default RadioGroup;
