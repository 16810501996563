import styled from 'styled-components';

export const CustomInput = styled.div`
  width: 100%;

  input {
    height: 46px;
    border: none;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.font.sizes.large};
    width: 100%;
    outline: none;
    margin-right: 0.5rem;
    padding: 0.5rem 0;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &::placeholder {
      color: ${({ theme }) => theme.colors.black};
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.disabled};
      color: ${({ theme }) => theme.colors.black};
      cursor: not-allowed;
    }

    &[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background: linear-gradient(
          45deg,
          transparent 0%,
          transparent 43%,
          #a9a9a9 45%,
          #a9a9a9 55%,
          transparent 57%,
          transparent 100%
        ),
        linear-gradient(
          135deg,
          transparent 0%,
          transparent 43%,
          #a9a9a9 45%,
          #a9a9a9 55%,
          transparent 57%,
          transparent 100%
        );
    }

    &[type='file'] {
      padding: 0;
    }

    &[type='file']::file-selector-button {
      background-color: ${({ theme }) => theme.colors.tertiary};
      height: 100%;
      color: ${({ theme }) => theme.colors.white};
      border: none;
      cursor: pointer;
      font-size: 14px;
      width: 150px;
    }
  }

  textarea {
    border: none;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.font.sizes.large};
    width: 100%;
    outline: none;
    margin-right: 0.5rem;
    padding: 0.5rem 0;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.disabled};
      color: ${({ theme }) => theme.colors.black};
      cursor: not-allowed;
    }
  }
`;
