import styled from 'styled-components';
import Theme from '~/styles/theme';
import Row from '~/components/molecules/Row';

export const Line = styled.hr`
  background-color: ${Theme.colors.primary};
  margin-bottom: 30px;
`;

export const SpecialRow = styled(Row)`
  ${({ isFirst }) => (isFirst ? 'margin-top: 0;' : '')}
`;

export const OperatorContent = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const AddContainer = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? '30px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TrashContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    width: 30px;
    height: 30px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;
