import styled, { css } from 'styled-components';
import { ASSETS } from '~/constants';

export const Container = styled.div`
  width: 100%;
  height: 250px;
  animation: changeBg 30s infinite;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & > div.container {
    height: 200px;
    display: flex;
    justify-content: flex-end;
  }

  @keyframes changeBg {
    0% {
      background-image: url(${ASSETS.IMAGES.BANNER_01});
    }
    20% {
      background-image: url(${ASSETS.IMAGES.BANNER_01});
    }
    25% {
      background-image: url(${ASSETS.IMAGES.BANNER_02});
    }
    48% {
      background-image: url(${ASSETS.IMAGES.BANNER_02});
    }
    50% {
      background-image: url(${ASSETS.IMAGES.BANNER_03});
    }
    70% {
      background-image: url(${ASSETS.IMAGES.BANNER_03});
    }
    75% {
      background-image: url(${ASSETS.IMAGES.BANNER_04});
    }
    95% {
      background-image: url(${ASSETS.IMAGES.BANNER_04});
    }
    100% {
      background-image: url(${ASSETS.IMAGES.BANNER_01});
    }
  }
`;

export const Label = styled.span`
  ${({ theme }) => css`
    background: rgba(199, 199, 199, 0.84);
    border-radius: ${theme.border.radius};
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.sizes.large};
    color: ${theme.colors.white};
    align-self: end;
    padding: 10px 13px;
    line-height: 150%;
    width: 100%;
  `}
`;
