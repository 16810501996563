export default {
  colors: {
    primary: '#009BE6',
    primaryLight: '#51BDF1',
    primaryDark: '#006F9B',
    primarySuperDark: '#064c65',
    secondary: '#47C973',
    secondaryLight: '#6dd591',
    secondaryDark: '#00B43D',
    success: '#0EA01A',
    successDark: '#17853E',
    tertiary: '#9394f4',
    tertiaryDark: '#5C58C7',
    warningLight: 'rgba(241,122,55,0.18)',
    warning: '#F17A37',
    warningDark: '#bd5a1d',
    error: '#f06767',
    errorLight: 'rgba(240,103,103,0.48)',
    errorDark: '#DA4F4F',
    textPrimary: 'rgba(0, 0, 0, 0.87)',
    textSecondary: 'rgba(0, 0, 0, 0.54)',
    textTertiary: '#868E96',
    textHint: 'rgba(0, 0, 0, 0.38)',
    textDisabled: 'rgba(0, 0, 0, 0.38)',
    textIcon: 'rgba(0, 0, 0, 0.38)',
    gray100: '#E3E3E3',
    gray: '#B5C2C8',
    disabled: '#dcdddd',
    grayLight: '#F9F9F9',
    skeletonBase: 'rgba(165,165,165,0.4)',
    skeletonHint: '#F5F5F5',
    grayMedium: '#6C8592',
    grayDark: '#202020',
    background: '#fff',
    offWhite: '#F5F7F8',
    light: '#ECEDF2',
    white: 'rgb(255,255,255)',
    black: '#2E2D37',
    yellow: '#F2CB00',
    green: '#89D329',
    purple: 'rgba(120, 121, 241, 0.9)',
    transparent: 'transparent',
    pdf: '#dd0000',
    doc: '#195abd',
    ppt: '#d96d50',
    xls: '#107c42',
  },
  font: {
    family: {
      normal: 'Roboto, sans-serif',
    },
    sizes: {
      xxSmall: '9px',
      small: '12px',
      normal: '14px',
      medium: '16px',
      large: '18px',
    },
    btn: {
      small: '0.755rem',
      medium: '0.875rem',
      large: '1.175rem',
    },
    weight: {
      normal: 400,
      bold: 700,
    },
  },
  transition: {
    default: '0.3s ease all',
    fast: '0.1s ease-in-out',
  },
  shadow: {
    default: '2px 2px 4px rgba(0, 0, 0, 0.12)',
    defaultElevated: '2px 2px 5px rgba(0, 0, 0, .3)',
    primary: '0 0 5px 1px rgba(239, 83, 80, 0.4)',
  },
  border: {
    radius: '4px',
    color: '#868e96',
  },
};
