import produce from 'immer';
import { REQUEST_ASSOCIATIONS, SET_LIST_ASSOCIATIONS, FETCH_LIST_ASSOCIATIONS } from './patterns';

const INITIAL_STATE = {
  associations: {
    requesting: false,
    error: false,
    list: {
      total: 0,
      orders: [],
    },
  },
};

export default function associations(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_LIST_ASSOCIATIONS: {
        draft.associations = {
          requesting: false,
          error: false,
          list: action.payload.data,
        };
        break;
      }
      case REQUEST_ASSOCIATIONS: {
        draft.associations.requesting = true;
        break;
      }
      case FETCH_LIST_ASSOCIATIONS: {
        draft.associations.requesting = action.payload.requesting;
        draft.associations.error = action.payload.error;
        break;
      }
      default:
    }
  });
}
