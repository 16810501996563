export const ENVIRONMENTS = {
  LOCAL: 'local',
  LOCAL_NP: 'local-np',
  DEV: 'dev',
  UAT: 'uat',
  PROD: 'prod',
};

export const JWT_TOKEN_STORAGE = 'omegaToken';

export const HTTP_STATUS_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const CATCH_PAYLOAD = { loading: false, error: true };
