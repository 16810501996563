import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretRight, CaretLeft, CaretDoubleRight, CaretDoubleLeft } from 'phosphor-react';

import { Footer, ButtonsContainer, Button, Select } from './style';

function Pagination({
  itemsPerPage,
  page,
  totalItems,
  onChangeItemsPerPage,
  onChangePage,
  customLinesPerPage,
  customOf,
}) {
  const { t } = useTranslation('components');

  const [_itemsPerPage, _setItemsPerPage] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(page);
  const [pages, setPages] = useState();
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();

  useEffect(() => {
    setStartIndex(currentPage * _itemsPerPage + 1 - _itemsPerPage);
    setEndIndex(
      currentPage * _itemsPerPage > totalItems ? totalItems : currentPage * _itemsPerPage,
    );
  }, [currentPage, _itemsPerPage, totalItems]);

  useEffect(() => {
    if (page === 1) {
      setStartIndex(1);
      setEndIndex(_itemsPerPage);
    }
  }, [page]);

  const first = (shootEvent = true) => {
    setCurrentPage(1);
    if (shootEvent) onChangePage(1);
  };

  const previous = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      onChangePage(currentPage - 1);
    }
  };

  const next = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
      onChangePage(currentPage + 1);
    }
  };

  const last = () => {
    setCurrentPage(pages);
    onChangePage(pages);
  };

  const changeItemsPerPage = (number) => {
    _setItemsPerPage(number);
    onChangeItemsPerPage(number);
    first(false);
  };

  useEffect(() => {
    setPages(Math.ceil(totalItems / _itemsPerPage));
  }, [_itemsPerPage, totalItems]);

  return (
    <Footer>
      {customLinesPerPage || `${t('linesPerPage')}`}
      <Select value={_itemsPerPage} onChange={(e) => changeItemsPerPage(Number(e.target.value))}>
        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
      </Select>
      {startIndex}-{endIndex} {customOf || `${t('of')}`} {totalItems}
      <ButtonsContainer>
        <Button disabled={currentPage === 1} onClick={first}>
          <CaretDoubleLeft />
        </Button>
        <Button disabled={currentPage === 1} onClick={previous}>
          <CaretLeft />
        </Button>
        <Button disabled={endIndex === totalItems} onClick={next}>
          <CaretRight />
        </Button>
        <Button disabled={endIndex === totalItems} onClick={last}>
          <CaretDoubleRight />
        </Button>
      </ButtonsContainer>
    </Footer>
  );
}

export default Pagination;
