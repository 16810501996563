export const dataAccounts = {
  total: 6,
  content: [
    {
      id: 1,
      name: 'Name 1',
      country: 'BR',
      documentType: 'CNPJ',
      document: 'Document 1',
      accountClassification: 'Account Classification 1',
    },
    {
      id: 2,
      name: 'Name 2',
      country: 'BR',
      documentType: 'CNPJ',
      document: 'Document 2',
      accountClassification: 'Account Classification 2',
    },
    {
      id: 3,
      name: 'Name 3',
      country: 'BR',
      documentType: 'CNPJ',
      document: 'Document 3',
      accountClassification: 'Account Classification 3',
    },
    {
      id: 4,
      name: 'Name 4',
      country: 'BR',
      documentType: 'CNPJ',
      document: 'Document 4',
      accountClassification: 'Account Classification 4',
    },
    {
      id: 5,
      name: 'Name 5',
      country: 'BR',
      documentType: 'CNPJ',
      document: 'Document 5',
      accountClassification: 'Account Classification 5',
    },
    {
      id: 6,
      name: 'Name 6',
      country: 'BR',
      documentType: 'CNPJ',
      document: 'Document 6',
      accountClassification: 'Account Classification 6',
    },
  ],
};
