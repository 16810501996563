import { REQUEST_ACCOUNTS, SET_GROUPING } from './patterns';

export function requestAccounts(params) {
  return {
    type: REQUEST_ACCOUNTS,
    payload: {
      params,
    },
  };
}

export function setGrouping({ data }) {
  return {
    type: SET_GROUPING,
    payload: { data },
  };
}
