import React from 'react';
import PropTypes from 'prop-types';
import { ASSETS } from '~/constants';
import * as S from './style';

function Tooltip({ info, ...props }) {
  const showInfo = false;

  return (
    <S.Container {...props}>
      <S.IconInfo src={ASSETS.ICONS.INFO_CIRCLE} alt="icon info" id="info" />
      {showInfo && (
        <>
          <S.Polygon src={ASSETS.ICONS.POLYGON} alt="icon polygon" />
          <S.Ballon>{info}</S.Ballon>
        </>
      )}
    </S.Container>
  );
}

Tooltip.propTypes = {
  info: PropTypes.any,
};

Tooltip.defaultProps = {
  info: '',
};

export default Tooltip;
