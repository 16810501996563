import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { CircleNotch } from 'phosphor-react';
import * as S from './style';

function Button({
  title,
  variant,
  fullWidth,
  iconPosition,
  href,
  size,
  type,
  loading,
  outline,
  onClick,
  dataTestId,
  dataQa,
  iconLeft,
  iconRight,
  disabled,
  ...props
}) {
  const handle = () => {
    if (!loading && !disabled) onClick();
  };

  const createRipple = (event) => {
    const button = event.currentTarget;

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('ripple');

    const ripple = button.getElementsByClassName('ripple')[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  };

  useEffect(() => {
    const buttons = document.getElementsByTagName('button');
    for (const button of buttons) {
      button.addEventListener('click', createRipple);
    }
  }, []);

  return (
    <S.Button
      data-testid={dataTestId}
      data-qa={dataQa}
      variant={variant}
      outline={outline}
      size={size}
      type={type}
      onKeyDown={handle}
      onClick={handle}
      full={fullWidth}
      disabled={disabled}
      role="button"
      {...props}
      {...(href && {
        href,
        as: 'a',
        role: 'link',
      })}
    >
      {loading ? (
        <CircleNotch className="--loading-btn" variant={variant} />
      ) : (
        <>
          {iconLeft}
          {title}
          {iconRight}
        </>
      )}
    </S.Button>
  );
}

Button.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'modal']),
  variant: PropTypes.oneOf([
    'primary',
    'primaryDark',
    'secondary',
    'tertiary',
    'warning',
    'error',
    'white',
    'gray',
    'link',
  ]),
  fullWidth: PropTypes.bool,
  outline: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
};

Button.defaultProps = {
  title: 'Click me',
  size: 'medium',
  variant: 'primary',
  fullWidth: false,
  outline: false,
  href: '',
  type: 'button',
  dataTestId: 'web-button',
  onClick: () => {},
};

export default Button;
