import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CustomInput } from './style';

function TextArea({
  rows = 5,
  onChange,
  onBlur = () => {},
  register,
  name,
  value,
  defaultValue,
  ...rest
}) {
  const {
    onChange: onChangeRegister,
    onBlur: onBlurRegister,
    ...restRegister
  } = register ? register(name) : { onBlur, onChange };

  const [prepareValue, setPrepareValue] = useState(defaultValue || value);

  useEffect(() => {
    setPrepareValue(defaultValue || value);
  }, [defaultValue, value]);

  const handleBlur = (e) => {
    if (onBlurRegister) onBlurRegister(e);
  };

  return (
    <CustomInput>
      <textarea
        rows={rows}
        onChange={(event) => {
          onChange(event);
          if (register) {
            onChangeRegister({
              target: { value: event.target.value, name },
            });
          }
        }}
        onBlur={handleBlur}
        defaultValue={prepareValue}
        {...restRegister}
        {...rest}
      />
    </CustomInput>
  );
}

TextArea.propTypes = {
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Provide name for Input */
  name: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  register: undefined,
  name: 'web-input',
  onChange: () => {},
};

export default TextArea;
