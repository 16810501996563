import PropTypes from 'prop-types';
import React from 'react';
import ContainerPage from '../ContainerPage';

import * as S from './style';

function Banner({ label, ...props }) {
  return (
    <S.Container {...props}>
      <ContainerPage>{label && <S.Label>{label}</S.Label>}</ContainerPage>
    </S.Container>
  );
}

Banner.propTypes = {
  label: PropTypes.string,
};

Banner.defaultProps = {
  label: '',
};

export default Banner;
