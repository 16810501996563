import { Info, SlidersHorizontal } from 'phosphor-react';
import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import Col from '~/components/molecules/Col';
import Row from '~/components/molecules/Row';
import Button from '../Button';
import { ContainerFilters } from './style';

function ShowFilters({ filter, children }) {
  const { t } = useTranslation('default');

  const [showFilters, setShowFilters] = useState(false);

  const handleFilters = () => {
    setShowFilters(!showFilters);
  };

  function textInfoShowFilters() {
    const text = t('infoButtonShowFilters').split("'");
    return `<span class="-is-light">
        ${text[0]}
        <strong class="-is-bold">"${text[1]}"</strong>
        ${text[2]}
        <strong class="-is-bold">"${text[3]}"</strong>
      </span>`;
  }

  function textInfoHideFilters() {
    const text = t('infoButtonHideFilters').split("'");
    return `<span class="-is-light">
        ${text[0]}
        <strong class="-is-bold">"${text[1]}"</strong>
        ${text[2]}
      </span>`;
  }

  return (
    <ContainerFilters>
      <Row>
        <Col className="is-4">
          <Button
            title={showFilters ? t('hideFilters') : t('showFilters')}
            iconLeft={<SlidersHorizontal />}
            iconRight={<Info id="info-button" weight="fill" />}
            onClick={handleFilters}
            fullWidth
          />
          <ReactTooltip
            anchorId="info-button"
            html={showFilters ? textInfoHideFilters : textInfoShowFilters}
            variant="light"
            style={{
              borderRadius: 4,
              width: 276,
              opacity: 1,
              textAlign: 'center',
              padding: 14,
              zIndex: 100,
              filter: 'drop-shadow(0px 4.65493px 11.6373px rgba(0, 0, 0, 0.25))',
            }}
          />
        </Col>
        {children}
      </Row>
      {showFilters && filter}
    </ContainerFilters>
  );
}

export default ShowFilters;
