import styled from 'styled-components';

export const About = styled.section`
  padding: 60px 0 0;
`;

export const Container = styled.section`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;

  .columns {
    padding-top: 30px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px !important;
  }

  @media (max-width: 992px) {
    max-width: 960px;
  }

  @media (max-width: 768px) {
    max-width: 720px;
  }
`;

export const Title = styled.h2`
  color: #0c617f;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
`;

export const Line = styled.div`
  width: 30%;
  height: 4px;
  background-color: #0c617f;
  margin: 10px 0;
`;

export const ImageFluid = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Text = styled.p`
  color: #10384f;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0;
  line-height: 1.2;

  > strong {
    font-weight: bold;
  }
`;

export const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.hr`
  width: 95%;
  opacity: 0.3;
  border-bottom: 1px solid #2e2d37;
`;
