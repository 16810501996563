export const dataActionPlan = [
  {
    label: 'Mine Plan',
    value: 1,
  },
  {
    label: 'Your Plan',
    value: 2,
  },
  {
    label: 'Our Plan',
    value: 3,
  },
  {
    label: 'That Plan',
    value: 4,
  },
  {
    label: 'This Plan ',
    value: 5,
  },
];
