export const fontFamilyBaseDefault = 'Roboto, sans-serif';

export const h1 = '22px';
export const h2 = '20px';
export const h3 = '18px';
export const h4 = '16px';
export const h5 = '16px';
export const h6 = '15px';
export const p = '14px';

export const fontWeightLight = '300';
export const fontWeightNormal = '500';
export const fontWeightBold = '700';

export const lineHeightBase = 1.5;

export const spacer = '1rem';
