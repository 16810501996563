export const ASSETS = {
  ICONS: {
    CALENDAR: '/assets/icon/calendar.svg',
    GROUP: '/assets/icon/group.svg',
    CHECKBOX_CHECKED: '/assets/icon/checkbox-checked.svg',
    RADIO_CHECKED: '/assets/icon/radio-checked.svg',
    RADIO_CHECKED_ERROR: '/assets/icon/radio-checked-error.svg',
    RADIO_CHECKED_SUCCESS: '/assets/icon/radio-checked-success.svg',
    DOLLAR_POSITIVE: '/assets/icon/dollar-positive.svg',
    DOLLAR_NEGATIVE: '/assets/icon/dollar-negative.svg',
    INFO_CIRCLE: '/assets/icon/info-circle.svg',
    POLYGON: '/assets/icon/polygon.svg',
    EYE: '/assets/icon/eye.svg',
    STAR: '/assets/icon/star.svg',
    NOTE: '/assets/icon/note.svg',
  },
  IMAGES: {
    BANNER_01: '/assets/images/banner-01.png',
    BANNER_02: '/assets/images/banner-02.png',
    BANNER_03: '/assets/images/banner-03.png',
    BANNER_04: '/assets/images/banner-04.png',
    BANNER_HOME: '/assets/images/banner-hero-produtor.png',
    BANNER_FOOTER: '/assets/images/converse-bayer.png',
  },
};
