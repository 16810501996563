import styled from 'styled-components';

export const Modal = styled.div``;
export const ModalBackground = styled.div`
  background-color: rgba(10, 10, 10, 0.5);
`;
export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '794px'};
  padding: 24px 40px;
`;

export const ModalClose = styled.button`
  &.modal-close-header {
    position: absolute !important;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryDark};
    }
    &::before {
      background-color: ${({ theme }) => theme.colors.grey};
      width: 55%;
    }
    &::after {
      background-color: ${({ theme }) => theme.colors.grey};
      height: 55%;
    }
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 14px;
`;

export const Title = styled.h2`
  font-size: 22px !important;
  color: ${({ color, theme }) => color || theme.colors.primaryDark};
  font-weight: 500;
  font-style: normal;
  text-align: center;
`;

export const SubTitle = styled.p`
  font-size: 15px !important;
  font-weight: ${({ theme }) => theme.font.weight.normal};
  color: ${({ theme }) => theme.colors.grayMedium};
  text-align: center;
  white-space: pre-wrap;
`;

export const Description = styled.span`
  font-size: 15px !important;
  font-weight: ${({ theme }) => theme.font.weight.normal};
  color: ${({ theme }) => theme.colors.grayMedium};
  font-style: italic;
  text-align: center;
  white-space: pre-wrap;
`;

export const Body = styled.div`
  width: 100%;
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 40px !important;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
`;
