import { REQUEST_ACCOUNTS, SET_LIST_ACCOUNTS } from './patterns';

export function requestAccounts(params) {
  return {
    type: REQUEST_ACCOUNTS,
    payload: {
      params,
    },
  };
}

export function setListAccounts({ data }) {
  return {
    type: SET_LIST_ACCOUNTS,
    payload: { data },
  };
}
