import styled from 'styled-components';
import themeDefault from '../../../styles/theme';

export const customStyles = ({ withFullBorder, withError }) => {
  const fullBorder = {
    height: '48px',
    border: `1px solid ${withError ? themeDefault.colors.error : themeDefault.colors.gray}`,
    borderRadius: '5px',
    background: themeDefault.colors.white,
  };

  const lineBorder = {
    height: '43px',
    border: 'none',
    borderBottom: `1px solid ${themeDefault.colors.grayLight}`,
    borderRadius: '0',
    '&:hover': {
      borderBottom: `1px solid ${themeDefault.colors.grayLight}`,
    },
  };

  const prepareBorder = withFullBorder ? fullBorder : lineBorder;

  return {
    control: (provider, state) => ({
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: state.isDisabled
        ? `${themeDefault.colors.gray100}!important`
        : 'transparent',
      minWidth: '80px',
      boxSizing: 'border-box',
      transition: '0.3s ease all',
      ...prepareBorder,
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 8px 2px 8px',
      fontSize: '16px',
    }),
    container: (provided, state) => ({
      ...provided,
      pointerEvents: 'all',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    menuList: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      cursor: 'pointer',
      color: state.isSelected ? themeDefault.colors.white : themeDefault.colors.grayDark,
      backgroundColor: state.isSelected ? themeDefault.colors.primary : themeDefault.colors.white,
      '&:hover': {
        color: themeDefault.colors.dark,
        backgroundColor: state.isSelected ? themeDefault.colors.primary : themeDefault.colors.light,
      },
    }),

    placeholder: (provided) => ({
      ...provided,
      fontSize: '16px',
      color: themeDefault.colors.black,
    }),
  };
};

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 87.9px;
`;

export const CustomOption = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  b {
    font-weight: 700;
    color: ${({ active }) =>
      active ? themeDefault.colors.white : themeDefault.colors.dark}!important;
  }
  & > svg {
    color: ${({ active }) =>
      active ? themeDefault.colors.white : themeDefault.colors.grey}!important;
    font-size: 16px !important;
  }
  &:hover {
    svg {
      color: ${themeDefault.colors.dark}!important;
    }
  }
`;
