import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Body = styled.div`
  padding-top: 20px;
`;

export const ContainerUnamed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    font-size: 16px;
  }
`;
