import styled from 'styled-components';
import Theme from '~/styles/theme';
import Row from '~/components/molecules/Row';

export const ContainerDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  border: 2px solid ${Theme.colors.light};
  padding: 10px;
`;

export const Line = styled.div`
  border: solid 2px ${Theme.colors.light};
  height: 150px;
`;

export const GroupDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LineDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  gap: 5px;
`;

export const DetailsName = styled.span`
  font-weight: bold;
  font-size: 10pt;
`;

export const DetailsValue = styled.span`
  font-size: 10pt;
  max-width: 200px;
`;

export const SpecialRow = styled(Row)`
  display: flex;
`;

export const OperatorContent = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const AddContainer = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? '30px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TrashContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    width: 30px;
    height: 30px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;
