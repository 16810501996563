import React, { useCallback, useEffect, useState } from 'react';
import { Check, CircleNotch } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ActionButton, ShowFilters } from '~/components/atoms';
import { Table } from '~/components/molecules';
import { requestAccounts } from '~/store/modules/main/accounts/actions';

import theme from '~/styles/theme';
import { ActionsTableButtons } from '~/components/styled';
import { CreateAssociation } from '~/services';
import { toast } from 'react-toastify';
import { DOCUMENT_OPERATOR } from '~/constants';
import Filter from './Filter';

function ModalAccount({ choseItem, toggle }) {
  const { t } = useTranslation('association');
  const dispatch = useDispatch();

  const { accounts } = useSelector((state) => state.accounts);

  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    country: 'BR',
    documentOperator: DOCUMENT_OPERATOR[1].value,
    documents: choseItem.documents.map((elm) => ({
      type: elm?.governmentTaxCode || elm.type,
      value: elm.value,
    })),
  });

  const getListAccounts = useCallback(() => {
    dispatch(requestAccounts(params));
  }, [params, dispatch]);

  useEffect(() => {
    getListAccounts();
  }, [getListAccounts]);

  const onChangeSort = (sort) => {
    setParams({ ...params, orderBy: [sort.selector], order: sort.order });
  };

  const onChangePage = (page) => {
    setParams({ ...params, page });
  };

  const onChangeItemsPerPage = (total) => {
    setParams({ ...params, page: 1, perPage: total });
  };

  const join = async (item) => {
    try {
      setLoading(true);
      await CreateAssociation({
        uuid: choseItem.uuid,
        c360: item.sourceId,
      });
      toggle(null, true);
    } catch (err) {
      toast.error(err?.data?.error || err?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: t('name'),
      selector: 'name',
    },
    {
      name: t('groupCode'),
      selector: 'accountBRs',
      custom: (accountBRs) =>
        accountBRs && Array.isArray(accountBRs)
          ? accountBRs.map((elm) => elm.groupCode).join(' | ') || '-'
          : '-',
    },
    {
      name: t('document'),
      selector: 'documents',
      custom: (documents) =>
        documents.map((elm, index) => {
          let prepare = `${elm?.governmentTaxCode || elm.type}: ${elm.value}`;
          if (documents.length > 1 && index !== documents.length - 1) {
            prepare = `${prepare} | `;
          }
          return prepare;
        }),
    },
    {
      name: t('accountClassification'),
      selector: 'classifications',
      custom: (classifications) =>
        classifications && Array.isArray(classifications)
          ? classifications.map((elm) => elm).join(' | ')
          : '-',
    },
    {
      name: t('actions'),
      selector: 'id',
      custom: (id, line) => (
        <ActionsTableButtons>
          <ActionButton
            tooltip={t('performAssociation')}
            id={`accountAssociation-${id}`}
            bgColor={theme.colors.secondary}
            bgColorHover={theme.colors.secondaryDark}
          >
            {loading ? (
              <CircleNotch className="--loading-btn" color={theme.colors.white} />
            ) : (
              <Check
                size="32"
                color={theme.colors.white}
                onClick={() => {
                  join(line);
                }}
              />
            )}
          </ActionButton>
        </ActionsTableButtons>
      ),
    },
  ];

  return (
    <>
      <ShowFilters
        filter={<Filter params={params} setParams={setParams} choseItem={choseItem} />}
      />
      <Table
        columns={columns}
        data={accounts?.list?.account}
        totalItems={accounts.list.totalCount}
        page={params.page}
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        loading={accounts?.requesting}
        error={accounts?.error}
        retry={getListAccounts}
      />
    </>
  );
}

export default ModalAccount;
