import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { CustomInput } from './style';

function MaskInput({ register, name, onChange, onBlur, mask, value, defaultValue, ...rest }) {
  const ref = useRef(null);

  const [prepareValue, setPrepareValue] = useState(value || defaultValue || '');

  const {
    onChange: onChangeRegister,
    onBlur: onBlurRegister,
    ...restRegister
  } = register ? register(name) : { onBlur, onChange };

  useEffect(() => {
    const prepare = value || defaultValue || '';
    setPrepareValue(prepare);
  }, [value, defaultValue]);

  return (
    <CustomInput>
      <InputMask
        ref={ref}
        mask={mask}
        value={prepareValue}
        maskChar={null}
        onChange={(event) => {
          onChange(event);
          setPrepareValue(event.target.value);
          if (register) {
            onChangeRegister({
              target: { value: event.target.value, name },
            });
          }
        }}
        onBlur={onBlurRegister}
        {...restRegister}
        {...rest}
      />
    </CustomInput>
  );
}

MaskInput.propTypes = {
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Provide name for Input */
  name: PropTypes.string,
  /** Provide mask for Input */
  mask: PropTypes.string,
  onChange: PropTypes.func,
};

MaskInput.defaultProps = {
  mask: null,
  register: undefined,
  name: 'web-input',
  onChange: () => {},
};

export default MaskInput;
