import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  text-align: center;

  h1 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`;
