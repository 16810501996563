import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { ErrorContainerInput, ErrorInput } from '~/components/styled';
import { INPUT_TYPE } from '~/constants';
import Custom from './Custom';
import * as S from './style';

const CheckboxGroup = forwardRef(
  ({ id, name, label, options, value = [], error, onChange, register, disabled = false }, ref) => {
    const [checkedValue, setCheckedValue] = useState([...value]);
    const [forceNewValue, setForceNewValue] = useState(null);

    const { onChange: onChangeRegister, onBlur } = register ? register(name) : { onChange };

    useEffect(() => {
      setCheckedValue([...value]);
    }, [value]);

    useEffect(() => {
      if (forceNewValue) {
        setCheckedValue(forceNewValue);
        if (onChangeRegister) {
          onChangeRegister({
            target: { value: forceNewValue.length !== 0 ? forceNewValue : null, name },
          });
        }
        setForceNewValue(null);
      }
    }, [forceNewValue]);

    const forceValue = (newValue) => {
      setForceNewValue(newValue);
    };

    useImperativeHandle(ref, () => ({
      forceValue,
    }));

    return (
      <S.Container data-testid="web-checkbox-input-container">
        {label ? (
          <S.CustomLabel data-testid="web-checkbox-input-label" error={error?.message}>
            {label}
          </S.CustomLabel>
        ) : null}

        <S.RadioRow>
          {options.map((op) => (
            <Custom
              id={id || name}
              key={shortid()}
              type={INPUT_TYPE.CHECKBOX}
              label={op.label}
              value={op.value}
              withCustomContent={op.withCustomContent}
              error={error?.message}
              onChange={(chose, bool) => {
                const prepare = bool
                  ? [...new Set([...checkedValue, chose])]
                  : checkedValue.filter((elm) => elm !== chose);
                setCheckedValue(prepare);
                onChange(prepare);
                if (onChangeRegister)
                  onChangeRegister({
                    target: { value: prepare.length !== 0 ? prepare : null, name },
                  });
              }}
              checked={checkedValue.includes(op.value)}
              onBlur={onBlur}
              disabled={disabled}
            />
          ))}
        </S.RadioRow>
        <ErrorContainerInput>
          {error?.message ? (
            <ErrorInput data-testid="web-radio-input-error">{error.message}</ErrorInput>
          ) : null}
        </ErrorContainerInput>
      </S.Container>
    );
  },
);

CheckboxGroup.propTypes = {
  /** ID for the Input */
  id: PropTypes.string,
  /** label for the Input */
  label: PropTypes.string,
  /** Name for the Input */
  name: PropTypes.string,
  /** ID for the Input */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  /** Corresponding value for the Input in the component */
  value: PropTypes.any,
  /** Provide function to handle text Input changes */
  onChange: PropTypes.func,
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Error message */
  error: PropTypes.object,
};

CheckboxGroup.defaultProps = {
  id: null,
  name: null,
  label: null,
  value: '',
  onChange: () => {},
  register: undefined,
  error: {},
};

export default CheckboxGroup;
