import { takeLatest, call, put, all } from 'redux-saga/effects';
import qs from 'qs';

import { GetInconsistences } from '~/services';
import { CATCH_PAYLOAD } from '~/constants';
import { toast } from 'react-toastify';
import { dataInconsistences } from '~/mocks/dataInconsistences';
import { REQUEST_ACCOUNTS, FETCH_ACCOUNT_GROUPING } from './patterns';
import { setGrouping } from './actions';

const SET_MOCK = true;

export function* sagaRequestAssociatedAccounts({ payload }) {
  try {
    const prepareParams = qs.stringify({ ...payload.params, onlyAssociates: true });
    const { data } = yield call(() => GetInconsistences(prepareParams));

    yield put(
      setGrouping({
        data,
      }),
    );
  } catch (err) {
    if (SET_MOCK) {
      yield put(
        setGrouping({
          data: dataInconsistences,
        }),
      );
    } else {
      yield put({ type: FETCH_ACCOUNT_GROUPING, payload: CATCH_PAYLOAD });
      toast.error(err?.data?.error || err?.data?.message || err?.message);
    }
  }
}

export default all([takeLatest(REQUEST_ACCOUNTS, sagaRequestAssociatedAccounts)]);
