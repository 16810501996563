import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import reactotronSaga from 'reactotron-redux-saga';
import { ENVIRONMENTS } from '~/constants';

if (process.env.NODE_ENV === ENVIRONMENTS.LOCAL) {
  const tron = Reactotron.configure().use(reactotronRedux()).use(reactotronSaga()).connect();

  tron.clear();
  console.tron = tron;
}
