import styled from 'styled-components';

export const ContainerFilters = styled.div`
  margin-bottom: 32px;
`;

export const BoxFilters = styled.div`
  background: rgba(200, 200, 200, 0.19);
  border-radius: 4px;
  padding: 24px;

  div.column {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 16px;
  button {
    margin-top: 8px;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 15px;
  gap: 10px;
  width: 100%;

  img {
    width: 16px;
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;
