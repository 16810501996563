import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CustomInput } from './style';

function NormalInput({ onChange, onBlur, register, name, value, defaultValue, isFile, ...rest }) {
  const {
    onChange: onChangeRegister,
    onBlur: onBlurRegister,
    ...restRegister
  } = register ? register(name) : { onChange };

  const [prepareValue, setPrepareValue] = useState(value || defaultValue || '');

  useEffect(() => {
    const prepare = value || defaultValue || '';
    setPrepareValue(prepare);
  }, [value, defaultValue]);

  const handleBlur = (e) => {
    if (onBlurRegister) onBlurRegister(e);
    onBlur(e);
  };

  return (
    <CustomInput>
      <input
        onChange={(event) => {
          onChange(event);
          setPrepareValue(event.target.value);
          if (register) {
            onChangeRegister({
              target: { value: event.target.value, name },
            });
          }
        }}
        value={!register && !isFile ? prepareValue : undefined}
        {...restRegister}
        onBlur={handleBlur}
        {...rest}
      />
    </CustomInput>
  );
}

NormalInput.propTypes = {
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Provide name for Input */
  name: PropTypes.string,
  onChange: PropTypes.func,
};

NormalInput.defaultProps = {
  register: undefined,
  name: 'web-input',
  onChange: () => {},
};

export default NormalInput;
