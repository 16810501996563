import * as S from './style';

function Container({ children, className, isHome, ...rest }) {
  return (
    <S.ContainerPage isHome={isHome} className={`container ${className}`} {...rest}>
      {children}
    </S.ContainerPage>
  );
}

export default Container;
