import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import uniqId from 'uniqid';
import { createListMock } from '~/helpers';
import Skeleton from '~/components/molecules/Skeleton';
import { Container, Label, ModalContainer, ModalOption, OptionLoading } from './style';
import Input from '../Input';

const CLASS_NAME_MODAL = 'auto-complete-modal-container';
const CLASS_NAME_OPTION = 'auto-complete-modal-option';

function InputAutoComplete({ callApi, onSelect, name, register, ...rest }) {
  const [visibled, setVisibled] = useState(false);
  const [myTimeout, setMyTimeout] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prepareValue, setPrepareValue] = useState(null);

  const { onChange: onChangeRegister } = register ? register(name) : {};

  const onChoose = (obj) => {
    onSelect(obj);
    setPrepareValue(obj.value);
    setVisibled(false);
    if (register)
      onChangeRegister({
        target: { value: obj.value, name },
      });
  };

  const onChange = (event) => {
    const value = event?.target?.value;
    if (register)
      onChangeRegister({
        target: { value: '', name },
      });
    if (myTimeout) clearTimeout(myTimeout);
    setMyTimeout(
      setTimeout(() => {
        setVisibled(true);
        callApi({ value, setList, setLoading });
      }, 500),
    );
  };

  const eventListener = (event) => {
    const className = event?.target?.className;
    const isString = typeof className === 'string';

    if (isString) {
      if (!className?.includes(CLASS_NAME_MODAL) && !className?.includes(CLASS_NAME_OPTION)) {
        setVisibled(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', eventListener);
    return () => {
      document.removeEventListener('click', eventListener);
    };
  }, []);

  return (
    <Container>
      <Input onChange={onChange} value={prepareValue} {...rest} />
      <ModalContainer visibled={visibled} className={CLASS_NAME_MODAL}>
        {!loading
          ? list.map((elm) => (
              <ModalOption
                className={CLASS_NAME_OPTION}
                key={uniqId()}
                onClick={() => onChoose(elm)}
              >
                <Label>{elm.label}</Label>
              </ModalOption>
            ))
          : null}

        {loading
          ? createListMock(10).map(() => (
              <ModalOption className={CLASS_NAME_OPTION} key={uniqId()}>
                <OptionLoading>
                  <Skeleton height={20} />
                </OptionLoading>
              </ModalOption>
            ))
          : null}
      </ModalContainer>
    </Container>
  );
}

InputAutoComplete.propTypes = {
  callApi: PropTypes.func,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  register: PropTypes.func,
};

InputAutoComplete.defaultProps = {
  callApi: {},
  onSelect: () => {},
  name: 'web-input',
  register: undefined,
};

export default InputAutoComplete;
