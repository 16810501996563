import React from 'react';
import { ArrowClockwise } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import * as S from './style';

function Retry({ retry }) {
  const { t } = useTranslation('default');

  return (
    <S.ContainerError onClick={retry} className="--container-error-retry">
      <ArrowClockwise size={32} />
      <S.ErrorText>{t('retry')}</S.ErrorText>
    </S.ContainerError>
  );
}

export default Retry;
