import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSETS } from '~/constants';
import { MagnifyingGlass, CircleNotch, Eye, EyeSlash } from 'phosphor-react';
import {
  InputContainer,
  ContainerLabelFloat,
  LabelInput,
  LabelFloat,
  LoadingInput,
  ErrorInput,
  ErrorContainerInput,
} from '../../styled';
import Mask from './Mask';
import Normal from './Normal';
import Currency from './Currency';
import TextArea from './TextArea';

const Input = forwardRef(
  (
    {
      id,
      label,
      labelFloat,
      withFullBorder,
      type,
      value,
      defaultValue,
      mask,
      textArea,
      rowsTextArea,
      currency,
      error,
      onChange,
      register,
      onBlur,
      onFocus,
      name,
      disabled = false,
      loading = false,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [filled, setFilled] = useState(value || defaultValue || currency);
    const [prepareType, setPrepareType] = useState(
      (type === 'password' && showPassword) || type === 'date' ? 'text' : type,
    );

    const handleChange = (e) => {
      if (currency) setFilled(true);
      if (mask) setFilled(e.target.value !== '');
      if (!currency && !mask) setFilled(e.target.value !== '');
      onChange(e);
    };

    const handleBlur = (e) => {
      if (!e.target.value && type === 'date') {
        setPrepareType('text');
      }
      if (onBlur) onBlur(e);
    };

    const handleFocus = (e) => {
      if (type === 'date') {
        setPrepareType('date');
      }
      if (onFocus) onFocus(e);
    };

    return (
      <ContainerLabelFloat filled={filled} isTextArea={textArea} data-testid="web-input-container">
        {labelFloat ? (
          <LabelFloat
            error={error?.message}
            htmlFor={id}
            className={`--label-float-input ${filled ? '--filled' : ''}`}
          >
            {label}
          </LabelFloat>
        ) : (
          <LabelInput error={error?.message}>{label}</LabelInput>
        )}
        <InputContainer
          withFullBorder={withFullBorder}
          withError={error?.message}
          isFile={type === 'file'}
          disabled={disabled}
          className="--container-input"
        >
          {mask && !currency ? (
            <Mask
              mask={mask}
              name={name}
              register={register}
              id={id}
              data-testid="web-input"
              type={prepareType}
              disabled={disabled}
              value={value}
              onChange={handleChange}
              {...rest}
            />
          ) : null}

          {!mask && currency ? (
            <Currency
              id={id}
              data-testid="web-input"
              type={prepareType}
              onChange={handleChange}
              register={register}
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
              value={value}
              ref={ref}
              {...rest}
            />
          ) : null}

          {!mask && !currency && !textArea ? (
            <Normal
              id={id}
              data-testid="web-input"
              type={prepareType}
              onChange={handleChange}
              register={register}
              name={name}
              defaultValue={!register ? value : undefined}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={disabled}
              value={value}
              isFile={type === 'file'}
              {...rest}
            />
          ) : null}

          {textArea && !mask && !currency ? (
            <TextArea
              id={id}
              rows={rowsTextArea}
              data-testid="web-input-text-area"
              onChange={handleChange}
              register={register}
              name={name}
              disabled={disabled}
              defaultValue={defaultValue}
              value={value}
              onBlur={handleBlur}
              {...rest}
            />
          ) : null}

          {type === 'password' && showPassword ? (
            <Eye onClick={() => setShowPassword(!showPassword)} />
          ) : type === 'password' && !showPassword ? (
            <EyeSlash onClick={() => setShowPassword(!showPassword)} />
          ) : null}
          {type === 'date' && <img src={ASSETS.ICONS.CALENDAR} alt="" />}
          {type === 'search' && <MagnifyingGlass style={{ width: '20px', height: '20px' }} />}

          {loading ? (
            <LoadingInput disabled={disabled}>
              <CircleNotch />
            </LoadingInput>
          ) : null}
        </InputContainer>
        <ErrorContainerInput>
          {error?.message ? (
            <ErrorInput data-testid="web-input-error">{error.message}</ErrorInput>
          ) : null}
        </ErrorContainerInput>
      </ContainerLabelFloat>
    );
  },
);
Input.propTypes = {
  /** ID for the Input */
  id: PropTypes.string,
  /** A label for the Input */
  label: PropTypes.string,
  /** Provide a label float */
  labelFloat: PropTypes.bool,
  /** Provide a complete border for input */
  withFullBorder: PropTypes.bool,
  /** Provide a specific type for the Input */
  type: PropTypes.oneOf(['date', 'password', 'text', 'search', 'number', 'file']),
  /** Corresponding value for the Input in the component */
  value: PropTypes.any,
  /** Corresponding defaultValue for the Input in the component */
  defaultValue: PropTypes.any,
  /** Provide function to handle text Input changes */
  onChange: PropTypes.func,
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Provide name for Input */
  name: PropTypes.string,
  /** Provide mask for Input */
  mask: PropTypes.string,
  /** Provide currency for Input */
  currency: PropTypes.bool,
  /** Provide textArea for Input */
  textArea: PropTypes.bool,
  /** Loading input */
  loading: PropTypes.bool,
  /** disabled input */
  disabled: PropTypes.bool,
  /** Error message */
  error: PropTypes.object,
};

Input.defaultProps = {
  id: null,
  label: null,
  labelFloat: false,
  withFullBorder: true,
  mask: null,
  currency: false,
  textArea: false,
  type: 'text',
  value: '',
  defaultValue: '',
  onChange: () => {},
  register: undefined,
  loading: false,
  disabled: false,
  name: 'web-input',
  error: {},
};

export default Input;
