import axios from 'axios';
import { ENVIRONMENTS, HTTP_STATUS_CODES, JWT_TOKEN_STORAGE } from '~/constants';
import store from '~/store';
import { setInvalidToken } from '~/store/modules/config/auth/actions';

export const envURL = () => {
  const config = {
    URL: process.env.REACT_APP_API_SELLOUT_URL || 'http://env-not-found',
    X_API_KEY: process.env.REACT_APP_API_SELLOUT_X_API_KEY,
  };

  return config;
};

const parseJwt = (token) => {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const isJWT = (jwt) => {
  const jwtSplit = jwt?.split('.');
  return jwtSplit?.length === 3;
};

export const getToken = () => {
  const tag = document.getElementsByTagName('html')[0].baseURI;
  const tagValue = tag?.split('=')?.[1];
  const TOKEN_OMEGA = isJWT(tagValue) ? tagValue : null;

  if (TOKEN_OMEGA) localStorage.setItem(JWT_TOKEN_STORAGE, TOKEN_OMEGA);

  const TOKEN_SAVE = TOKEN_OMEGA || localStorage.getItem(JWT_TOKEN_STORAGE);

  switch (process.env.REACT_APP_SCOPE) {
    case ENVIRONMENTS.LOCAL:
    case ENVIRONMENTS.LOCAL_NP:
      return {
        token: process.env.REACT_APP_API_SELLOUT_TOKEN_JWT_MOCK,
        tokenData: parseJwt(process.env.REACT_APP_API_SELLOUT_TOKEN_JWT_MOCK),
      };
    case ENVIRONMENTS.DEV:
    case ENVIRONMENTS.UAT:
    case ENVIRONMENTS.PROD:
      return {
        token: TOKEN_OMEGA || TOKEN_SAVE,
        tokenData: parseJwt(TOKEN_OMEGA || TOKEN_SAVE),
      };
    default:
      return {
        token: TOKEN_OMEGA || TOKEN_SAVE,
        tokenData: parseJwt(TOKEN_OMEGA || TOKEN_SAVE),
      };
  }
};

export const Http = () => {
  const { URL, X_API_KEY } = envURL();

  const client = axios.create({
    baseURL: URL,
    timeout: 120000,
  });

  client.interceptors.request.use(
    (config) => {
      const { token } = getToken();
      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['accept-language'] = 'pt-BR';

        if (X_API_KEY) config.headers['x-api-key'] = X_API_KEY;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error?.response?.status;
      if (status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        store.dispatch(setInvalidToken());
      }
      if (status === HTTP_STATUS_CODES.FORBIDDEN) {
        // TODO - Navigate to unauthorized screen
      }
      return Promise.reject(error.response);
    },
  );

  return client;
};
