import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import NumericFormat from 'react-number-format';
import { CustomInput } from './style';

const formatCurrencyForInput = (formattedValue) => {
  if (formattedValue === '') return '';
  if (!Number(formattedValue)) return 'R$ 0,00';
  const br = { style: 'currency', currency: 'BRL' };
  return new Intl.NumberFormat('pt-BR', br).format(formattedValue / 100);
};

const Currency = forwardRef(
  ({ register, name, onChange, onBlur, min, max, value, defaultValue, ...rest }, ref) => {
    const { onChange: onChangeRegister, onBlur: onBlurRegister } = register
      ? register(name)
      : { onBlur, onChange };
    const [prepareValue, setPrepareValue] = useState(value || defaultValue || '');

    const keyDown = (e) => {
      if (e.code === 'Backspace' && !prepareValue) {
        e.preventDefault();
      }
      if (e.code === 'Backspace' && prepareValue < 1) {
        e.preventDefault();
        setPrepareValue(0);
      }
    };

    useEffect(() => {
      const prepare = value || defaultValue;
      const prepareFormat = Math.round(prepare * 100);
      setPrepareValue(prepareFormat);
    }, [value, defaultValue]);

    const resetField = () => setPrepareValue(0);

    useImperativeHandle(ref, () => ({
      resetField,
    }));

    return (
      <CustomInput>
        <NumericFormat
          {...rest}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          prefix="R$ "
          allowNegative={false}
          fixedDecimalScale
          allowEmptyFormatting
          format={formatCurrencyForInput}
          value={prepareValue !== 0 ? parseFloat(prepareValue).toString().replace('.', ',') : ''}
          onKeyDown={keyDown}
          onValueChange={(v) => {
            setPrepareValue(v.floatValue);
            onChange(v.floatValue / 100);
            if (register) {
              onChangeRegister({
                target: { value: v.floatValue / 100, name },
              });
            }
          }}
          isAllowed={(val) => {
            const { floatValue } = val;
            if (!floatValue) return true;
            if (min || max) {
              return floatValue >= min && floatValue <= max;
            }
            return true;
          }}
          onBlur={onBlurRegister}
        />
      </CustomInput>
    );
  },
);

Currency.propTypes = {
  /** Provide function to Hook Form */
  register: PropTypes.func,
  /** Provide name for Input */
  name: PropTypes.string,
  onChange: PropTypes.func,
};

Currency.defaultProps = {
  register: undefined,
  name: 'web-input',
  onChange: () => {},
};

export default Currency;
