import styled from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grayDark};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  font-size: ${({ theme }) => theme.font.sizes.small};
`;

export const Select = styled.select`
  color: ${({ theme }) => theme.colors.grayDark};
  border: none;
  margin-right: 1rem;
  margin-left: 0.5rem;
  font-size: ${({ theme }) => theme.font.sizes.small};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 50%;
  padding: 0.3125rem;
  margin: 0.3125rem;
  height: 1.75rem;
  width: 1.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.grayDark};
  cursor: pointer;

  span {
    width: 0 !important;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grayLight};
    color: ${({ theme }) => theme.colors.grayDark};
  }

  &:active {
    outline: none;
    border: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray};
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  > svg,
  i {
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }
`;
