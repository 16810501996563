import React from 'react';
import { useTranslation } from 'react-i18next';

import { ENVIRONMENTS } from '~/constants';
import { Container } from './styles';

function Footer() {
  const { t, i18n } = useTranslation('home');

  return (
    <Container>
      <div className="overlay">
        <div className="bg" />
        <div className="line" />
      </div>
      <div className="container">
        <p className="subtitle">{t('contactTitle')}</p>
        <h1 className="title">{t('contact')}</h1>
        <p className="text">{t('contactNumber')}</p>
        <p className="text">{t('contactTimesheet')}</p>
        {process.env.REACT_APP_SCOPE !== ENVIRONMENTS.PROD && (
          <div
            style={{
              zIndex: 10000,
              position: 'relative',
            }}
          >
            <button onClick={() => i18n.changeLanguage('en')} type="button">
              EN
            </button>
            <button onClick={() => i18n.changeLanguage('es')} type="button">
              ES
            </button>
            <button onClick={() => i18n.changeLanguage('pt')} type="button">
              PT
            </button>
          </div>
        )}
      </div>
    </Container>
  );
}

export default Footer;
