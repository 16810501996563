import { REQUEST_ASSOCIATIONS, SET_LIST_ASSOCIATIONS } from './patterns';

export function requestAssociations(params) {
  return {
    type: REQUEST_ASSOCIATIONS,
    payload: {
      params,
    },
  };
}

export function setListAssociations({ data }) {
  return {
    type: SET_LIST_ASSOCIATIONS,
    payload: { data },
  };
}
