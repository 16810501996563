import { ArrowClockwise, PlusCircle } from 'phosphor-react';
import Skeleton from '~/components/molecules/Skeleton';
import * as S from './style';

function SectionSeparator({
  icon,
  title,
  titleColor,
  sectionColor,
  info,
  register,
  registerText,
  loading,
  error,
  retry,
  errorText,
}) {
  return (
    <>
      <S.Section color={sectionColor} titleColor={titleColor}>
        <S.Title titleColor={titleColor}>
          {icon} {title}
        </S.Title>
        {loading && (
          <S.ContainerLoading>
            <Skeleton height={24} style={{ borderRadius: 4 }} />
          </S.ContainerLoading>
        )}
        {error && !loading ? (
          <S.ContainerError onClick={retry}>
            <ArrowClockwise size={16} />
            <S.Error>{errorText}</S.Error>
          </S.ContainerError>
        ) : null}
        {!loading && !error ? <>{info && <>{info}</>}</> : null}
        {register && (
          <S.Register onClick={register}>
            <PlusCircle size={22} weight="fill" color="#47C973" />
            {registerText}
          </S.Register>
        )}
      </S.Section>
    </>
  );
}

export default SectionSeparator;
