import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { PropTypes } from 'prop-types';
import theme from '~/styles/theme';

function Box({ children }) {
  return (
    <div
      style={{
        lineHeight: 0,
      }}
    >
      {children}
    </div>
  );
}

function SkeletonCustom({ baseColor, highlightColor, width, height, ...rest }) {
  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <Skeleton wrapper={Box} height={height} width={width} {...rest} />
    </SkeletonTheme>
  );
}

SkeletonCustom.defaultProps = {
  baseColor: theme.colors.skeletonBase,
  highlightColor: theme.colors.skeletonHint,
  width: '100%',
  height: undefined,
};

SkeletonCustom.propTypes = {
  baseColor: PropTypes.string,
  highlightColor: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
};

export default SkeletonCustom;
