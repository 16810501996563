import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from '~/components/molecules';
import { ASSETS } from '~/constants';
import {
  About,
  Container,
  Divider,
  DividerContainer,
  ImageFluid,
  Line,
  Text,
  Title,
} from './style';

function Home() {
  const { t } = useTranslation('home');

  return (
    <>
      <About>
        <Container>
          <Title>{t('contentTitle')}</Title>
          <Line />
          <Row>
            <Col>
              <ImageFluid src={ASSETS.ICONS.NOTE} alt="note" />
              <Text>
                <strong>{t('goalPlan')}</strong>
              </Text>
              <Text>{t('goalPlanDescription')}</Text>
            </Col>
            <Col>
              <ImageFluid src={ASSETS.ICONS.EYE} alt="olho" />
              <Text>
                <strong>{t('Pegasus')}</strong>
              </Text>
              <Text>{t('pegasusDescription')}</Text>
            </Col>
            <Col>
              <ImageFluid src={ASSETS.ICONS.STAR} alt="estrela" />
              <Text>
                <strong>{t('IBDP')}</strong>
              </Text>
              <Text>{t('IBDPDescription')}</Text>
            </Col>
          </Row>
          <DividerContainer>
            <Divider />
          </DividerContainer>
        </Container>
      </About>
    </>
  );
}

export default Home;
