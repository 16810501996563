import { Gear } from 'phosphor-react';
import i18n from '~/locales';

function t(item) {
  return i18n.logger.options.resources[i18n.resolvedLanguage].routes[item];
}

export const ROUTES = {
  MAIN: {
    ASSOCIATION: '/main/association',
  },
  OMEGA: {
    HOME: '/home/page',
  },
  DEVELOPMENT: {
    HOME: '/',
  },
  NOT_AUTHORIZED: 'not-authorized',
};

export const PROFILE_TYPES = {
  MAIN: '/main',
  HOME: '/home',
  NOT_AUTHORIZED: 'not-authorized',
};

export const MENU_MAIN = [
  {
    title: t('association'),
    icon: <Gear />,
    route: ROUTES.MAIN.ASSOCIATION,
  },
];
