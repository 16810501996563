import { SET_LOGIN, SET_LOGOUT, SET_INVALID_TOKEN } from './patterns';

export function login(token) {
  return {
    type: SET_LOGIN,
    payload: { token },
  };
}

export function setInvalidToken() {
  return {
    type: SET_INVALID_TOKEN,
  };
}

export function logout() {
  return {
    type: SET_LOGOUT,
  };
}
