import produce from 'immer';
import { REQUEST_ACCOUNTS, SET_LIST_ACCOUNTS, FETCH_LIST_ACCOUNTS } from './patterns';

const INITIAL_STATE = {
  accounts: {
    requesting: false,
    error: false,
    list: {
      total: 0,
      orders: [],
    },
  },
};

export default function associations(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_LIST_ACCOUNTS: {
        draft.accounts = {
          requesting: false,
          error: false,
          list: action.payload.data,
        };
        break;
      }
      case REQUEST_ACCOUNTS: {
        draft.accounts.requesting = true;
        break;
      }
      case FETCH_LIST_ACCOUNTS: {
        draft.accounts.requesting = action.payload.requesting;
        draft.accounts.error = action.payload.error;
        break;
      }
      default:
    }
  });
}
