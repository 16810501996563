export const formatCurrency = (formattedValue) => {
  const prepareNumber =
    typeof formattedValue === 'string' ? parseFloat(formattedValue) : formattedValue;
  const br = { style: 'currency', currency: 'BRL' };
  return new Intl.NumberFormat('pt-BR', br).format(prepareNumber);
};

export const createListMock = (limit = 4) => Array(limit).fill(true);

export const delay = (t, v) => {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(null, v), t);
  });
};

export const formatDate = (date) => {
  const prepareDate = date.split('T')[0].split('-');
  return `${prepareDate[2]}/${prepareDate[1]}/${prepareDate[0]}`;
};

export const formatDateUS = (date) => {
  const prepareDate = date.split('T')[0].split('-');
  return `${prepareDate[1]}/${prepareDate[2]}/${prepareDate[0]}`;
};

export const formatDateWithTime = (date) => {
  const prepareDate = date.split('T')[0].split('-');
  const prepareTime = date.split('T')[1].split('.');
  return `${prepareDate[2]}/${prepareDate[1]}/${prepareDate[0]} - ${prepareTime[0]}`;
};

export const formatDateWithLocalTimeZone = (date) => {
  const d = new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  const prepareTime = d.split(', ');
  return `${prepareTime[0]} - ${prepareTime[1]} `;
};

export const onlyMonthYear = (date) => {
  const prepareDate = date.split('T')[0].split('-');
  return `${prepareDate[1]}/${prepareDate[0]}`;
};
