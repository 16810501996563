import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '~/components/atoms';
import { useTranslation } from 'react-i18next';
import * as S from './style';

function Modal({
  isOpen,
  headerDefault,
  toggle,
  icon,
  title,
  titleColor,
  subTitle,
  description,
  showTopClose,
  showHeaderClose,
  actionsDefault,
  actionConfirmVariant,
  actionConfirm,
  actionConfirmLoading,
  actionTitle,
  actionDisabled,
  maxWidth,
  overflowVisible,
  children,
}) {
  const { t } = useTranslation('components');

  return (
    <S.Modal className={`modal ${isOpen ? 'is-active' : ''}`} data-testid="bayer-modal">
      <S.ModalBackground className="modal-background" onClick={toggle} />
      <S.ModalContent
        className={`modal-content ${overflowVisible ? '--overflow-visible' : ''}`}
        maxWidth={maxWidth}
      >
        {headerDefault ? (
          <S.Header>
            {showHeaderClose ? (
              <S.ModalClose
                type="button"
                className="modal-close modal-close-header is-medium"
                aria-label="close"
                onClick={toggle}
              />
            ) : null}
            {icon ? <S.IconContainer>{icon}</S.IconContainer> : null}
            <S.Title color={titleColor}>{title}</S.Title>
            <S.SubTitle>{subTitle}</S.SubTitle>
            <S.Description>{description}</S.Description>
          </S.Header>
        ) : null}

        <S.Body>
          {children}

          {actionsDefault ? (
            <S.ModalActions>
              <Button title={t('back')} variant="gray" size="modal" onClick={toggle} />
              <Button
                title={actionTitle || t('yes')}
                size="modal"
                disabled={actionDisabled}
                variant={actionConfirmVariant}
                loading={actionConfirmLoading}
                onClick={actionConfirm}
              />
            </S.ModalActions>
          ) : null}
        </S.Body>
      </S.ModalContent>

      {showTopClose ? (
        <S.ModalClose
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={toggle}
        />
      ) : null}
    </S.Modal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  headerDefault: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.any,
  subTitle: PropTypes.any,
  showTopClose: PropTypes.bool,
  showHeaderClose: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  headerDefault: true,
  toggle: undefined,
  icon: null,
  title: 'Modal Title',
  subTitle: 'Modal Subtitle',
  showTopClose: false,
  showHeaderClose: true,
};

export default Modal;
