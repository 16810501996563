import styled from 'styled-components';
import { ASSETS } from '~/constants';

export const Container = styled.footer`
  background: url(${ASSETS.IMAGES.BANNER_FOOTER}) no-repeat;
  background-size: auto;
  background-position: bottom right;
  width: 100%;
  height: 321px;
  display: flex;
  align-items: center;

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 321px;
    overflow: hidden;
  }

  .overlay .bg {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    width: 60%;
    height: 100%;
    background: #10384f;
    opacity: 0.8;
    transform: skewX(-10deg);
  }

  .overlay .line {
    background-color: #de0043;
    left: 59.5%;
    width: 1px;
    height: 110%;
    top: -20px;
    bottom: auto;
    position: absolute;
    z-index: 1;
    transform: translateX(0) rotate(15deg);
  }

  .subtitle:not(.is-spaced) + .title {
    margin-top: 0 !important;
  }

  .title {
    position: relative;
    z-index: 2;
    font-weight: 100;
    font-size: 60px;
    color: white;
    margin: 0;
    width: 60%;
  }

  .subtitle {
    position: relative;
    z-index: 2;
    font-weight: 100;
    font-size: 20px;
    color: white;
    margin: 0;
    font-weight: 500;
  }

  .text {
    position: relative;
    z-index: 2;
    font-weight: 100;
    font-size: 18px;
    color: yellow;
    margin: 0;
  }

  .overlay .bg {
    background-color: #009be6;
    left: -50px;
    width: 63%;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px !important;
    }
  }

  @media (max-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (max-width: 768px) {
    .container {
      max-width: 720px;
    }
    background-size: 100%;
    background-position: 80% 0px;
    align-items: end;

    .title {
      font-size: 30px;
      width: 100%;
      margin-bottom: 10px;
    }

    .text {
      margin-bottom: 20px;
    }

    .overlay .bg {
      top: 200px;
      left: 0;
      right: 0;
      height: calc(120% - 150px);
      opacity: 1;
      width: 100%;
      transform: translateX(0) skewY(-3deg);
    }

    .overlay .line {
      transform: translateX(0) rotate(-7deg);
      top: 200px;
      width: 100%;
      height: 1px;
      left: 0;
      position: absolute;
    }

    .hero-contact {
      background-size: 100%;
      background-position: top;
    }

    .bg,
    .line {
      top: 110px !important;
    }

    .text {
      margin: 0;
    }

    .text:last-child {
      margin-bottom: 50px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    background-size: 40%;
    background-position: center right;
  }

  @media (min-width: 1700px) {
    background-position: 84% bottom;
  }
`;
