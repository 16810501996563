import { combineReducers } from 'redux';

// MAIN
import grouping from './main/grouping/reducer';
import association from './main/associations/reducer';
import accounts from './main/accounts/reducer';

// CONFIG
import auth from './config/auth/reducer';

export default combineReducers({
  grouping,
  association,
  accounts,
  auth,
});
