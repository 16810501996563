import { all } from 'redux-saga/effects';

// MAIN
import grouping from './main/grouping/sagas';
import associations from './main/associations/sagas';
import accounts from './main/accounts/sagas';

export default function* rootSaga() {
  return yield all([
    // MAIN
    grouping,
    associations,
    accounts,
  ]);
}
