import i18n from '~/locales';

function t(item) {
  return i18n.logger.options.resources[i18n.resolvedLanguage].default[item];
}

export const PARTNER_PROFILES = {
  DISTRIBUTOR: 'DISTRIBUTOR',
  DEALER: 'DEALER',
  COOPERATIVE: 'COOPERATIVE',
};

export const YES_NO = [
  {
    value: 'true',
    label: t('yes'),
  },
  {
    value: 'false',
    label: t('no'),
  },
];

export const CLASSIFICATIONS = [
  {
    value: PARTNER_PROFILES.COOPERATIVE,
    label: PARTNER_PROFILES.COOPERATIVE,
  },
  {
    value: PARTNER_PROFILES.DISTRIBUTOR,
    label: PARTNER_PROFILES.DISTRIBUTOR,
  },
  {
    value: PARTNER_PROFILES.DEALER,
    label: PARTNER_PROFILES.DEALER,
  },
];

export const DOCUMENT_OPERATOR = [
  {
    value: 'and',
    label: t('and'),
  },
  {
    value: 'or',
    label: t('or'),
  },
];

export const DOCUMENT_TYPES = [
  {
    value: 'BR-CNPJ',
    label: 'BR-CNPJ',
  },
  {
    value: 'BR-IE',
    label: 'BR-IE',
  },
];

export const INPUT_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
};

export const ORIGIN_FILES = {
  CLOUD: 'CLOUD',
  LOCAL: 'LOCAL',
};

export const SALES_ORG_TYPES = {
  MWCS: 'Bayer',
  MCCS: 'Monsanto',
};
