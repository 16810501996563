import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './style';

function BannerHome() {
  const { t } = useTranslation('home');
  return (
    <S.Container>
      <S.Overlay>
        <S.Background />
        <S.Line />
      </S.Overlay>
      <S.Section>
        <S.Title>{t('bannerTitle')}</S.Title>
      </S.Section>
    </S.Container>
  );
}

export default BannerHome;
