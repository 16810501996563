import { takeLatest, call, put, all } from 'redux-saga/effects';
import qs from 'qs';

import { GetAssociations } from '~/services';
import { CATCH_PAYLOAD } from '~/constants';
import { toast } from 'react-toastify';
import { REQUEST_ASSOCIATIONS, FETCH_LIST_ASSOCIATIONS } from './patterns';
import { setListAssociations } from './actions';

export function* sagaRequestAssociations({ payload }) {
  try {
    const prepareParams = qs.stringify(payload.params);
    const { data } = yield call(() => GetAssociations(prepareParams));

    yield put(
      setListAssociations({
        data,
      }),
    );
  } catch (err) {
    yield put({ type: FETCH_LIST_ASSOCIATIONS, payload: CATCH_PAYLOAD });
    toast.error(err?.data?.error || err?.data?.message || err?.message);
  }
}

export default all([takeLatest(REQUEST_ASSOCIATIONS, sagaRequestAssociations)]);
