import React from 'react';
import { TableActionIconBtn } from '~/components/styled';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function ActionButton({ bgColor, bgColorHover, id, tooltip, children }) {
  return (
    <TableActionIconBtn id={id} bgColor={bgColor} bgColorHover={bgColorHover}>
      {children}
      <ReactTooltip
        anchorId={id}
        html={`<span class="-is-primary">${tooltip}</span>`}
        variant="light"
        style={{
          borderRadius: 4,
          width: 176,
          opacity: 1,
          textAlign: 'center',
          padding: 14,
          zIndex: 100,
          filter: 'drop-shadow(0px 4.65493px 11.6373px rgba(0, 0, 0, 0.25))',
        }}
      />
    </TableActionIconBtn>
  );
}

export default ActionButton;
