import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const MenuNavigation = styled.div`
  background-color: #009be6;
  padding: 14px 0;
  width: 100%;
`;
