import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
`;

export const Section = styled.div`
  margin-bottom: 10px;
  h4 {
    margin-top: 10px;
  }
`;

export const FormContainer = styled.div`
  max-width: 500px;
`;
