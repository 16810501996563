import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// EN
import ENActions from './en/actions.json';
import ENAssociation from './en/association.json';
import ENFeedbacks from './en/feedbacks.json';
import ENFields from './en/fields.json';
import ENHome from './en/home/index.json';
import ENRoutes from './en/routes.json';
import ENValidations from './en/validations.json';

// ES
import ESActions from './es/actions.json';
import ESAssociation from './es/association.json';
import ESFeedbacks from './es/feedbacks.json';
import ESFields from './es/fields.json';
import ESHome from './es/home/index.json';
import ESRoutes from './es/routes.json';
import ESValidations from './es/validations.json';

// PT
import PTActions from './pt/actions.json';
import PTAssociation from './pt/association.json';
import PTFeedbacks from './pt/feedbacks.json';
import PTFields from './pt/fields.json';
import PTHome from './pt/home/index.json';
import PTRoutes from './pt/routes.json';
import PTValidations from './pt/validations.json';

const ENDefault = { ...ENActions, ...ENFeedbacks, ...ENFields, ...ENValidations };

const ESDefault = { ...ESActions, ...ESFeedbacks, ...ESFields, ...ESValidations };

const PTDefault = { ...PTActions, ...PTFeedbacks, ...PTFields, ...PTValidations };

const resources = {
  en: {
    association: { ...ENAssociation, ...ENDefault },
    routes: { ...ENRoutes, ...ENDefault },
    default: { ...ENDefault },
    home: { ...ENHome },
  },
  es: {
    association: { ...ESAssociation, ...ESDefault },
    routes: { ...ESRoutes, ...ESDefault },
    default: { ...ESDefault },
    home: { ...ESHome },
  },
  pt: {
    association: { ...PTAssociation, ...PTDefault },
    routes: { ...PTRoutes, ...PTDefault },
    default: { ...PTDefault },
    home: { ...PTHome },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources,
    lng: navigator.language,
    fallbackLng: 'pt',
    keySeparator: false,
    nsSeparator: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
