import produce from 'immer';
import { SET_LOGIN, SET_LOGOUT, SET_INVALID_TOKEN } from './patterns';

const INITIAL_STATE = {
  logged: false,
  invalidToken: false,
  token: null,
};

export default function auth(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_LOGIN: {
        draft.logged = true;
        draft.token = action.payload.token;
        break;
      }
      case SET_LOGOUT: {
        draft.logged = false;
        draft.token = null;
        break;
      }
      case SET_INVALID_TOKEN: {
        draft.invalidToken = true;
        break;
      }
      default:
    }
  });
}
