import styled, { css } from 'styled-components';

const wrapperModifiers = {
  primary: (theme) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    transition: ${theme.transition.default};
  `,
  secondary: (theme) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.primary};
    transition: ${theme.transition.default};
  `,
  gray: (theme) => css`
    background-color: ${theme.colors.gray};
    color: ${theme.colors.white};
    transition: ${theme.transition.default};
  `,
};

export const Chip = styled.div`
  ${({ theme, variant }) => css`
    padding: 4px 7px;
    border-radius: 4px;
    width: auto;
    white-space: nowrap;
    position: relative;
    width: 100%;

    ${!!variant && wrapperModifiers[variant](theme)};

    svg {
      cursor: pointer;
      position: absolute;
      right: 6px;

      :hover {
        color: ${theme.colors.grayMedium};
        transition: ${theme.transition.default};
      }
    }
  `}
`;

export const RemoveButton = styled.button`
  cursor: pointer;
`;
