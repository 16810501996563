import React, { useCallback, useEffect, useState } from 'react';
import { Check, CircleNotch } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ActionButton, ShowFilters } from '~/components/atoms';
import { Table } from '~/components/molecules';

import theme from '~/styles/theme';
import { ActionsTableButtons } from '~/components/styled';
import { toast } from 'react-toastify';
import { setGrouping } from '~/services';
import { requestAccounts } from '~/store/modules/main/grouping/actions';
import Filter from './Filter';

function ModalGrouping({ choseItem, toggle }) {
  const { t } = useTranslation('association');
  const dispatch = useDispatch();

  const { associatedAccounts } = useSelector((state) => state.grouping);

  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    country: choseItem?.address?.country || 'BR',
    name: choseItem.name,
    onlyAssociates: false,
  });

  const getListAssociatedAccounts = useCallback(() => {
    dispatch(requestAccounts(params));
  }, [params, dispatch]);

  useEffect(() => {
    getListAssociatedAccounts();
  }, [getListAssociatedAccounts]);

  const onChangeSort = (sort) => {
    setParams({ ...params, orderBy: [sort.selector], order: sort.order });
  };

  const onChangePage = (page) => {
    setParams({ ...params, page });
  };

  const onChangeItemsPerPage = (total) => {
    setParams({ ...params, page: 1, perPage: total });
  };

  const join = async (item) => {
    try {
      setLoading(true);
      await setGrouping({
        dealerUuid: choseItem.uuid,
        dealerUuidMaster: item.uuid,
      });
      toggle(null, true);
    } catch (err) {
      toast.error(err?.data?.error || err?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: t('name'),
      selector: 'name',
    },
    {
      name: t('groupCode'),
      selector: 'relateds',
      custom: (relateds) =>
        relateds && Array.isArray(relateds)
          ? relateds.reduce(
              (acc, value) => (value?.source === 'group_code' ? value?.value || '-' : acc),
              '-',
            )
          : '-',
    },
    {
      name: t('document'),
      selector: 'documents',
      custom: (documents) =>
        documents.map((elm, index) => {
          let prepare = `${elm?.governmentTaxCode || elm.type}: ${elm.value}`;
          if (documents.length > 1 && index !== documents.length - 1) {
            prepare = `${prepare} | `;
          }
          return prepare;
        }),
    },
    {
      name: t('accountClassification'),
      selector: 'classification',
      custom: (classification) =>
        classification && Array.isArray(classification)
          ? classification.map((elm) => elm || '-')
          : '-',
    },
    {
      name: t('actions'),
      selector: 'id',
      custom: (id, line) => (
        <ActionsTableButtons>
          <ActionButton
            tooltip={t('performAssociation')}
            id={`accountAssociation-${id}`}
            bgColor={theme.colors.secondary}
            bgColorHover={theme.colors.secondaryDark}
          >
            {loading ? (
              <CircleNotch className="--loading-btn" color={theme.colors.white} />
            ) : (
              <Check
                size="32"
                color={theme.colors.white}
                onClick={() => {
                  join(line);
                }}
              />
            )}
          </ActionButton>
        </ActionsTableButtons>
      ),
    },
  ];

  return (
    <>
      <ShowFilters
        filter={<Filter params={params} setParams={setParams} choseItem={choseItem} />}
      />
      <Table
        columns={columns}
        data={associatedAccounts?.list?.accounts}
        totalItems={associatedAccounts?.list?.totalCount}
        page={params.page}
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        loading={associatedAccounts?.requesting}
        error={associatedAccounts?.error}
        retry={getListAssociatedAccounts}
      />
    </>
  );
}

export default ModalGrouping;
