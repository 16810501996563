import { Container, Text } from './style';

function NotAuthorized() {
  return (
    <Container>
      <Text>
        <h1>Not Authorized</h1>
        <span>Você não tem permissão para acessar essa página</span>
      </Text>
    </Container>
  );
}

export default NotAuthorized;
