import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, CheckboxGroup, Input, RadioGroup, Select } from '~/components/atoms';
import { Col, Form, Modal, Row } from '~/components/molecules';
import { useFormHook } from '~/hooks';
import { dataActionPlan } from '~/mocks/dataActionPlan';

import * as S from './styles';

const CHECKBOX = [
  { label: 'Checkbox 1', value: 1 },
  { label: 'Checkbox 2', value: 2 },
  { label: 'Checkbox 3', value: 3 },
];

const RADIO = [
  { label: 'Radio 1', value: 1 },
  { label: 'Radio 2', value: 2 },
  { label: 'Radio 3', value: 3 },
];

const schema = Yup.object().shape({
  name: Yup.string().required('Campo Obrigatório').nullable(),
  cpf: Yup.string().required('Campo Obrigatório'),
  rescueValue: Yup.string().required('Campo Obrigatório'),
  plan: Yup.mixed().required('Campo Obrigatório'),
  checkbox: Yup.mixed().required('Campo Obrigatório').nullable(),
  radio: Yup.mixed().required('Campo Obrigatório').nullable(),
});

function Home() {
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { formFunctions } = useFormHook(schema, {});

  const {
    register,
    reset,
    formState: { errors },
  } = formFunctions;

  const toggle = () => setIsOpenModal(!isOpenModal);

  const confirm = (obj) => {
    // eslint-disable-next-line no-console
    console.log('obj', obj);
  };

  const NavigateToAssociation = () => {
    navigate('/main/association');
  };

  const NavigateToHome = () => {
    navigate('/home/page');
  };

  return (
    <S.Container>
      <S.Section>
        <h1>Home</h1>
        Ambiente: {process.env.REACT_APP_SCOPE}
      </S.Section>

      <Row>
        <Col>
          <Button title="Acessar Tela de Associação" onClick={NavigateToAssociation} />
        </Col>
        <Col>
          <Button title="Acessar Home" onClick={NavigateToHome} />
        </Col>
      </Row>

      <S.Section>
        <hr />
        <h1>Components</h1>
        <h4>Form</h4>
      </S.Section>

      <S.FormContainer>
        <Form
          onSubmit={confirm}
          actionTitle="Enviar Form"
          schema={schema}
          actionPosition="center"
          formFunctions={formFunctions}
          cancelTitle="Resetar"
          hasCancel
          onClickCancel={reset}
          dataQaBtn="btnSave"
        >
          <Input
            name="name"
            withFullBorder
            label="Digite o nome*"
            register={register}
            error={errors?.name}
          />

          <Input
            id="cpf"
            name="cpf"
            label="CPF*"
            register={register}
            error={errors?.cpf}
            mask="999.999.999-99"
          />

          <Input
            name="rescueValue"
            currency
            label="Digite o valor do resgate*"
            min={0}
            max={99999999}
            register={register}
            error={errors?.rescueValue}
          />

          <CheckboxGroup
            name="checkbox"
            options={CHECKBOX}
            register={register}
            error={errors?.checkbox}
          />

          <RadioGroup name="radio" options={RADIO} register={register} error={errors?.radio} />

          <Select
            label="Estado de licenciamento*"
            name="plan"
            placeholder="Selecione o Plano"
            options={dataActionPlan}
            register={register}
            error={errors?.plan}
          />
        </Form>
      </S.FormContainer>

      <S.Section>
        <hr />
        <h4>Modal</h4>
        <Button title="Abrir Modal" onClick={toggle} />
      </S.Section>

      <Modal isOpen={isOpenModal} toggle={toggle}>
        <div>
          <p>Content Modal</p>
        </div>
      </Modal>
    </S.Container>
  );
}

export default Home;
