import createSagaMiddleware from 'redux-saga';
import { ENVIRONMENTS } from '~/constants';
import createStore from './createStore';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

import '../config/reactotronConfig';

const sagaMonitor =
  process.env.NODE_ENV === ENVIRONMENTS.LOCAL ? console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddleware];

const store = createStore(rootReducer, middlewares);

sagaMiddleware.run(rootSaga);

export default store;
