import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useWatch } from 'react-hook-form';
import i18n from '~/locales';
import Button from '../../atoms/Button';
import * as S from './style';

function t(item) {
  return i18n.logger.options.resources[i18n.resolvedLanguage].default[item];
}
function Form({
  actionTitle,
  cancelTitle,
  onSubmit,
  withCustomSubmit,
  actionLoading,
  actionVariant,
  hasWatch,
  hasCancel,
  onClickCancel,
  fullWidthBtn,
  disabledButton,
  hasDraft,
  draftTitle,
  disabledDraft,
  draftLoading,
  watch,
  actionPosition,
  formFunctions,
  dataQaBtn,
  children,
}) {
  const { handleSubmit, control } = formFunctions;

  const watchForm = useWatch({
    control,
  });

  useEffect(() => {
    if (hasWatch) watch(watchForm);
  }, [hasWatch, watchForm]);

  return (
    <FormProvider {...formFunctions}>
      <S.Form onSubmit={handleSubmit(onSubmit)} data-testid="web-form">
        {children}

        {!withCustomSubmit ? (
          <S.ButtonGroup actionPosition={actionPosition} hasCancel={hasCancel}>
            {hasCancel ? (
              <Button
                onClick={onClickCancel}
                fullWidth={fullWidthBtn}
                id="action-btn"
                variant="error"
                title={cancelTitle}
              />
            ) : null}
            {hasDraft ? (
              <Button
                onClick={handleSubmit((formObj, event) => {
                  onSubmit(formObj, event, { isDraft: true });
                })}
                fullWidth={fullWidthBtn}
                disabled={disabledDraft}
                loading={draftLoading}
                id="action-draft-btn"
                variant="primary"
                title={draftTitle}
              />
            ) : null}
            <Button
              fullWidth={actionPosition === 'center' || fullWidthBtn}
              id="action-btn"
              type="submit"
              variant={actionVariant}
              title={actionTitle}
              disabled={disabledButton}
              loading={actionLoading}
              data-qa={dataQaBtn}
            />
          </S.ButtonGroup>
        ) : null}
      </S.Form>
    </FormProvider>
  );
}

Form.propTypes = {
  /** Define hasWatch */
  hasWatch: PropTypes.bool,
  /** Define disabled */
  disabled: PropTypes.bool,
  /** Define watch */
  watch: PropTypes.func,
  /** Define action on submit */
  onSubmit: PropTypes.func,
  /** Provide a actionTitle for the button */
  actionTitle: PropTypes.string,
  /** Provide a dataQaB for the button */
  dataQaBtn: PropTypes.string,
  /** Provide a actionVariant for the button */
  actionVariant: PropTypes.string,
  /** Define loading for button */
  actionLoading: PropTypes.bool,
  /** Provide a actionPosition for the button */
  actionPosition: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between']),
  /** Define children */
  children: PropTypes.node,
  /** Define formFunctions */
  formFunctions: PropTypes.object,
};

Form.defaultProps = {
  hasWatch: false,
  disabled: false,
  watch: () => {},
  onSubmit: () => {},
  actionVariant: 'secondary',
  actionTitle: `${t('confirm')}`,
  actionLoading: false,
  actionPosition: 'center',
  children: null,
  dataQaBtn: null,
  formFunctions: {},
};

export default Form;
